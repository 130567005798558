@import '../../styles/common.styles.scss';
.loading {
  // background: #004AAD;
  position: absolute;
  top: 40%;
  left: 50%;
  transform-origin: 50% 50%;
  transform: translate(-50%, -50%);

  .loader-animation{
    width: 20vw;
  }
}

#loading3, #loading3::before, #loading3::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  background: #004AAD;
  transform-origin: 50% 50%;
}
#loading3::before {
  position: absolute;
  top: -1em;
  left: -0.4em;
  width: 1em;
  height: 2.5em;
  transform: rotate(-45deg);
  -moz-border-radius-bottomleft: 2em;
  -webkit-border-bottom-left-radius: 2em;
  border-bottom-left-radius: 2em;
  -moz-border-radius-topright: 2em;
  -webkit-border-top-right-radius: 2em;
  border-top-right-radius: 2em;
  animation: loading3b 2s infinite;
}
#loading3 {
  background: $red-color;
  height: 3em;
  width: 0.2em;
  -moz-border-radius-bottomright: 2em;
  -webkit-border-bottom-right-radius: 2em;
  border-bottom-right-radius: 2em;
  -moz-border-radius-bottomleft: 2em;
  -webkit-border-bottom-left-radius: 2em;
  border-bottom-left-radius: 2em;
}
#loading3::after {
  position: absolute;
  top: -1em;
  left: -0.4em;
  width: 1em;
  height: 2.5em;
  transform: rotate(45deg);
  -moz-border-radius-bottomleft: 2em;
  -webkit-border-bottom-left-radius: 2em;
  border-bottom-left-radius: 2em;
  -moz-border-radius-topright: 2em;
  -webkit-border-top-right-radius: 2em;
  border-top-right-radius: 2em;
  animation: loading3a 2s infinite;
}

@-moz-keyframes loading3b {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
@-webkit-keyframes loading3b {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
@keyframes loading3b {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(680deg);
  }
}
@-moz-keyframes loading3a {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(765deg);
  }
}
@-webkit-keyframes loading3a {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(765deg);
  }
}
@keyframes loading3a {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(765deg);
  }
}
