@import './styles/common.styles.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spacing {
  flex-grow: 1;
}

.Title {
  color: #fdc453;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  margin-left: 2.5vw;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 30px;
  border-bottom: 1px solid #eee;
  margin-right: 2.5vw;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.TitleSmall {
  color: #fdc453;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  margin-left: 2.5vw;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-top: 30px;
  border-bottom: 1px solid #eee;
  margin-right: 2.5vw;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.MuiTableRow-head {
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar  */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.main-app-bottom-nav-container{
  background-color: transparent !important;
}
.main-app-bottom-nav{
  background-color: #862c92 !important;
  z-index: 10;
}


@media screen and (orientation: landscape) {
  .main-app-bottom-nav{
    display: none !important;
  }
}

