.share-container {
    .share-network {
        vertical-align: top;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        share-button {
            cursor: pointer;
            &:hover:not(:active) {
                opacity: 0.75;
            }
        }
    }
}
