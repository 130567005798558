@import "../../styles/common.styles.scss";

.contactContainer {
  @include page-container;
  background-color: #fff;
  width: 100%;
  @include plainBackground;

  .contactImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
    }
  }

  .contactFormContainer {
    @include shadow;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin: 50px 10px;
    width: 90vw;

    .contactTitle {
      color: $purple-color;
      font-size: 1.1rem;
      letter-spacing: 1px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding-bottom: 10px;
      text-align: center;
    }

    .sendButton {
      text-align: center;
      padding-top: 30px;

      Button {
        background-color: $purple-color;
        letter-spacing: 1px;
      }

      .request-button {
        text-align: center;
        letter-spacing: 1px;
        background-color: $green-color;
        margin: 10px 5px;
      }

      .request-button:hover {
        background-color: $yellow-color;
      }
    }

    .contactInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      margin-top: 20px;
      color: $blue-color;

      span {
        vertical-align: top;
      }

      .whatsapp {
        vertical-align: top;
      }
    }
  }
}

.error-text {
  color: $red-color;
  font-size: small;
}

// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .contactContainer {
    width: 100vw;

    .contactFormContainer {
      width: 90vw;
    }

    .contactInfo {
      flex-direction: column;
      justify-content: center;
    }
  }
}