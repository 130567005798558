@import "../../styles/common.styles.scss";

.admin-subscription-details {
  @include page-container;
  padding: 20px;

  .subscriptionFormContainer {
    @include shadow;
    background-color: #fff;
    min-height: 150px;
    text-align: center;
    border-radius: 10px;
    animation: move-up 1s ease;
    margin: 10px 5px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    .search-total{
      margin-left: 25px;
      color: $blue-color;
      font-weight: 600;

    }
    .subscription-search{
      background-color: #fff;
      @include shadow;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 25px;
    .formField {
      Button {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }

    .subscriptionForm {
      display: flex;
      width: 100%;

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .formField {
          min-width: 30%;
          text-align: left;
        }
      }
    }

    .subscriptionTitle {
      text-align: left;
      letter-spacing: 1px;
      font-size: 1rem;
      color: $blue-color;
      margin-bottom: 10px;
      padding: 10px 20px;
      text-transform: uppercase;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 1px solid #ccc;
      font-weight: bold;
    }

    .sendButton {
      text-align: center;
      padding-top: 0px;

      Button {
        background-color: $purple-color;
        letter-spacing: 1px;
      }
    }

    .subscriptionInfo {
      display: flex;
      justify-content: space-around;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      margin-top: 20px;
      color: $blue-color;

      span {
        vertical-align: top;
        color: $purple-color;
      }
    }
  }
}

.error-text {
  color: $red-color;
  font-size: small;
}

// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .subscriptionContainer {
    .subscriptionFormContainer {
      margin: 20px 0;
      margin-left: 20px
    }

    .subscriptionInfo {
      flex-direction: column;
    }
  }
}