@import '../../styles/common.styles.scss';

.dailygk-page {
  height: 100vh;
  padding-bottom: 70px;
  @include plainBackground;

  .dailygk-searching{
    img{
      width: 50vw;
    }
  }

  .dailygk-loading{
    margin-top: 30px;
    img{
      width: 25vw;
    }  
  }
  
  .dailygk-filters {
    margin: 0px 20px 20px 20px;
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid $purple-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: .9rem;

    .MuiFormControl-root{
      min-width: 20vw !important;
    }

    .dailygk-filter-button {
      padding: 10px 30px;
      background-color: $primary-color;
      color: $yellow-color;
      border-radius: 20px;
      letter-spacing: 1px;
      margin-top: 10px;
    }

    .active-grade {
      background-color: $yellow-color;
      color: $primary-color;
    }

    .dailygk-filter-button:hover {
      cursor: pointer;
    }
  }

  .dailygk-number {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $yellow-color;
    color: $purple-color;
    padding: 3px 5px;
    border-radius: 3px;
    .dailygk-audio {
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: auto;

      span {
        button {
          background-color: #fff !important;
          width: 30px !important;
          height: 30px !important;
          border-radius: 50px !important;
          color: #fff !important;
          font-size: 2rem;
          padding-bottom: 10px !important;
          cursor: pointer;
        }
      }
    }
  }

  .dailygk-top-animation {
    padding-top: 5vh;
    display: flex;
    justify-content: center;

    .dailygk-page-top-animation {
     width: 35vw;
    }
  }
  .dailygk-top-section {
    min-height: 10vh;

  .dailygk-question {
    padding: 10px 10px;
    text-align: center;
    letter-spacing: 1px;
    color: $primary-color;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dailygk-image {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;

    .dailygk-question-animation {
      width: 50vw;
    }
  }
}

  .dailygk-options {
    padding-top: 20px;
    margin-top: -3px;


    .dailygk-option-row {
      display: flex;
      justify-content: space-between;

      .dailygk-option-value {
        background-color: #fff;
        color: #6d3ea2;
        font-size: 1.2rem;
        letter-spacing: 1px;
        width: 47vw;
        padding: 3vh 0px;
        margin-bottom: 4vh;
        text-align: center;
      }

      .dailygk-option-value:hover {
        background-color: $yellow-color;
        color: $primary-color;
      }

      .dailygk-right-answer {
        background-color: #48b86a;
        color: #fff;
      }

      .dailygk-wrong-answer {
        background-color: $red-color;
        color: #fff;
      }
    }

    .right-border {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .left-border {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .dailygk-history-button {
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      background-color: #fff;
      color: $primary-color;
      padding: 3px 5px;
      border-radius: 10px;
      @include shadow;
    }

    span:hover {
      cursor: pointer;
    }
  }
  .dailygk-buttons {
    text-align: center;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    .next-prev-button {
      margin: 0px 10px;

      img {
        width: 50px;
      }
    }

    .next-prev-button:hover {
      cursor: pointer;
    }

    button {
      background-color: $purple-color;
      color: #ffffff;
      padding: 10px 12px;
      letter-spacing: 1px;
      margin: 0px 10px;
    }

    .disableButton {
      background-color: #999;
    }
  }

}