@import '../../../../styles/common.styles.scss';

.activity-page {
  min-height: 100vh;
  @include plainBackground;
  padding-top: 60px;
  text-align: center;

  .upload-image-title{
    color: $primary-color;
    letter-spacing: 1px;
    padding: 20px 0px;
    margin-bottom: 10px;
  }

  .image-container{
    margin-top: 10px;
  img{
    max-width: 90vw;
    max-height: 50vh;
  }
  .file-image{
    margin-top: 10px;
    height: 1.5em;
    width: 1.5em;
    color: $primary-color;
  }
  }
  
  .timer-section { 
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

  }
}