@import '../../styles/common.styles.scss';

.video-player {
  padding-bottom: 20px;

  .video-card {
    background-color: #fff;
    margin: 15px 10px 20px 10px;
    border-radius: 10px;
    position: relative;
    @include shadow;

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .video-play-button {
      position: absolute;
      right: 5px;
      top: 5px;
      opacity: .7;

      img {
        width: 30px;
        // margin-top: -30px;
      }
    }

    .video-name {
      font-size: .9rem;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }

    .video-tag {
      font-size: .9rem;
      color: $gray-color;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }

  }

  .video-card:hover {
    cursor: pointer;
    animation: box-move-up .5s ease forwards;
  }
}



// Media queries
@media only screen and (max-width: 1023px) {
  .video-player {
    .video-card {
      margin: 10px;
    }
  }
}