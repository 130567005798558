@import '../../../styles/common.styles.scss';

.group-leaderboard-container {
  width: 100vw;
  background-color: $yellow-color;
  position: relative;
  z-index: 0;
  margin-top: 15px;
  @include backgroundStyle;

  .month-year {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    span {
      color: $primary-color;
    }
  }
  .leaderboard-page-top-section {
    display: flex;
    width: 100vw;
    justify-content: center;

    .leaderboard-page-top-section-animation {
      width: 50vw;
      height: 20vh
    }
  }

  .leaderboard-image {
    text-align: center;

    img {
      width: 80%;
    }
  }

  .leaderboard-list-container {
    margin-bottom: 50px;
    width: 100vw;

    .leaderboard-loading {
      text-align: center;
      color: $gray-color;

      img {
        width: 30%;
      }
    }

    .leaderboard-no-ranking {
      text-align: center;
      margin-top: 50px;
      font-size: 1.2rem;
      letter-spacing: 1px;
      color: $gray-color;
    }

    .leaderboard-filters {
      padding: 0px 0px 10px 0px;
      border-bottom: 1px solid $purple-color;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      font-size: .9rem;
      z-index: 1;

      .leaderboard-filter-button {
        padding: 5px 20px;
        background-color: #fff;
        border-radius: 20px;
        letter-spacing: 1px;
        margin-top: 10px;
        @include shadow;
      }

      .active-grade {
        background-color: $purple-color;
        color: #fff;
      }

      .leaderboard-filter-button:hover {
        cursor: pointer;
      }
    }

    .leaderboard-toppers {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin: 20px 0px;

      .leaderboard-toppers-card {
        @include shadow;
        min-height: 150px;
        width: 30vw;
        position: relative;
        background-color: #fff;
        animation: zoom-in-zoom-out 1s ease;
        border-radius: 10px;

        .toppers-medal {
          text-align: center;
          padding: 10px;

          img {
            width: 10vw;
          }
        }

        .toppers-image {
          text-align: center;
          padding: 0px 10px 0px 10px;
          margin-top: -10px;

          img {
            width: 10vw;
          }
        }

        .toppers-name {
          text-align: center;
          letter-spacing: 1px;
          color: $purple-color;
          font-size: .8rem;
          text-transform: capitalize;
        }

        .toppers-rank {
          text-align: center;
          font-size: 1rem;
          font-weight: bold;
          color: $blue-color;
          position: absolute;
          top: 0;
          left: 0;
          padding: 5px;

          span {
            color: #999;
            font-size: .8rem;
            vertical-align: middle;
            font-weight: normal;
          }
        }

        .toppers-info {
          margin-top: 40px;
          letter-spacing: 1px;
          color: $purple-color;
          font-weight: bold;

          .toppers-quiz {
            position: absolute;
            bottom: 10px;
            left: 10px;
          }

          .toppers-score {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }

          span {
            color: $gray-color;
            font-weight: normal;
          }
        }
      }
    }

    .leaderboard-rankRow {

      display: flex;
      justify-content: space-between;
      color: $gray-color;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 0px;
      background-color: #fff;
      border-radius: 10px;
      @include shadow;

      .rankRow-left {
        display: flex;
        align-items: center;

        .leaderboard-rankRow-rank {
          font-size: .8rem;
          font-weight: bold;
          margin-left: 10px;
          color: $blue-color;
        }

        .leaderboard-rankRow-image {
          margin-left: 20px;

          img {
            width: 40px;
          }
        }

        .leaderboard-rankRow-info {
          margin-left: 20px;
          font-size: .8rem;
          letter-spacing: 1px;
          font-weight: bold;
          color: $purple-color;
          text-transform: capitalize;

          .leaderboard-rankRow-info-details {
            font-size: .8rem;
            color: $gray-color;
          }
        }
      }

      .rankRow-right {
        .leaderboard-rankRow-medal {
          img {
            width: 40px;
          }
        }
      }
    }

    .leaderboard-my-rank {
      @include shadow;
      background-color: $purple-color;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      align-items: center;
      margin-left: 30%;
      margin-top: 40px;
      border-radius: 20px;
      animation: zoom-in-zoom-out 1s ease;

      .my-rank-image {
        margin-top: -50px;

        img {
          width: 75px;
        }
      }

      .my-rank-info {
        margin-top: -20px;
        color: $yellow-color;
        display: flex;
        letter-spacing: 1px;
        font-weight: bold;
        justify-content: space-between;
        width: 100%;
        text-align: center;

        .my-rank-name {
          font-size: 1.2rem;
          text-transform: capitalize;
        }

        .my-rank-score {
          font-size: 1.2rem;
        }

        span {
          display: block;
          color: #fff;
          font-size: 2rem;
        }
      }
    }

    .leaderboard-rank-list {
      width: 90vw;
      margin-left: 5vw;
      margin-top: 20px;
      animation: zoom-in-zoom-out 1s ease;

      .leaderboard-rank-list-pagination {
        padding: 20px;

        .MuiPagination-ul {
          justify-content: center;
        }

      }
    }
  }

  // Media queries
  @media only screen and (max-width: 767px) {

    /* For mobile phones: */
    .leaderboard-toppers {

      .leaderboard-toppers-card {
        margin-bottom: 20px;
      }
    }

    .leaderboard-my-rank {
      width: 70% !important;
      margin-left: 10% !important;
    }
  }
  .history-button {
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      background-color: #fff;
      color: $primary-color;
      padding: 3px 5px;
      border-radius: 10px;
      @include shadow;
    }

    span:hover {
      cursor: pointer;
    }
  }
 
}