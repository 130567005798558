@import "../../styles/common.styles.scss";

.payment-main {
  @include shadow;
  margin: 20px;
  padding: 15px;
  border-top: 10px solid $purple-color;
  background-color: #fff;
  border-radius: 10px;
  .payment-title {
    @include main-heading;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.controlBox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .paymentControl {
    min-width: 125px;
    margin: 10px;
    .paymentLabel {
      font-size: 1.2rem;
      color: $purple-color;
      font-weight: bold;
    }
    .paymentValue {
      font-size: 1.2rem;
      color: #333;
      font-weight: bold;
    }
  }
  .formButton {
    vertical-align: bottom;
  }
}
