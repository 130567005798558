@import "../../styles/common.styles.scss";

.group-landing-page {
    @include backgroundStyle;
    min-height: 100vh;
   .group-landing-inner{ 
    padding-top: 5vh;
    .group-banner {
        display: flex;
        background-color: #fff;
        width: 94vw;
        min-height: 15vh;
        border-radius: 10px;
        margin-left: 3vw;
        @include shadow;
        position: relative;
        img{
            border-radius: 10px;
            max-width: 90vw;
            height: 100%;
        }
    }
    .group-list {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .group-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;

            .group-image {
                display: flex;
                justify-content: center;
                .group-animation {
                    width: 30vw;
                }
            }

            .group-button {
                color: #fff;
                letter-spacing: 1px;
                background-color: $purple-color;
                margin: 10px 10px;
                padding: 0px 10px;
                border-radius: 30px;
                &:hover {
                    cursor: pointer;
                    background-color: $blue-color;
                    color: #fff;
                }
            }
        }
    }
   } 
}
