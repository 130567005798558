@import '../../../styles/common.styles.scss';

.riddle-listing-page {
  min-height: 100vh;
  @include plainBackground;
  position: relative;
  padding-bottom: 70px;


  .top-animation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #fff;

    .riddle-page-top-animation {
      width: 40vw;;
    }
  }

  .riddle-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;


    .riddle-card {
      width: 100vw;
      padding: 10px;
      padding-left: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;

      .riddle-header {
        width: 100%;
        letter-spacing: 1px;
        font-size: .8rem;
        border-top-left-radius: 1vh;
        border-top-right-radius: 1vh;
        color: $red-color;
      }
      .riddle-body {
        letter-spacing: 1px;
        color: $primary-color;

      }
      .riddle-footer {
        padding: 5px;
        letter-spacing: 1px;
        color: $primary-color;
        font-size: .6rem;
      }
      .riddle-answer{
        font-size: 1rem;
        color: $green-color;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 10px;
      }
    }
  }
  .riddle-pagination{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}