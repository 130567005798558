@import "../../styles/common.styles.scss";

  .bulk-payment-main{
    @include shadow;
    margin: 20px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    .bulk-payment-title {
      @include main-heading;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    .save-button{
      margin: 10px 10px 10px 0px;
    }
  }