@import '../../../../styles/common.styles.scss';

.illustration-pro {
  display: grid;
  grid-template-rows: 24px auto;
  justify-items: center;
  position: relative;
}

.illustration-pro .code {
  background: #552255;
  width: 100%;
  height: 277px;
  border-radius: 10px;
  text-align: center;
}

.illustration-pro .code img {
  width: 100%;
}

.illustration-pro .toggle {
  position: relative;
  display: inline-block;
  min-width: 10vw;
  border-radius: 2em;
  font-size: 1.2rem;
  background-color: $yellow-color;
  vertical-align: middle;
  margin: -12px 5px 0;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 3px 7px;
}

.illustration-pro .toggle:hover {
  cursor: pointer;
  background-color: $purple-color;
  color: #fff;
}


.illustration-pro .blinds {
  display: grid;
  justify-items: center;
  position: relative;
  height: 307px;
  overflow: hidden;
}

.illustration-pro .blinds-top {
  z-index: 10;
  width: 100%;
  height: 14px;
  background: $purple-color;
  border-radius: 5px;
  position: relative;
}

.illustration-pro .blinds-top::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 14px;
  top: 0;
  left: 0;
  border-radius: 100%;
  background: #202125;
}

.illustration-pro .blinds-rows {
  z-index: 9;
  position: relative;
  margin: 6px 0 0;
  padding: 0;
  height: 300px;
}

.illustration-pro .blinds-rows::before,
.illustration-pro .blinds-rows::after {
  opacity: 0;
  content: "";
  position: absolute;
  top: 0;
  width: 2px;
  height: 230px;
  background: #fff;
  top: -5px;
}

.illustration-pro .blinds-rows::before {
  left: 50px;
}

.illustration-pro .blinds-rows::after {
  right: 50px;
}

.illustration-pro .blinds-row {
  transition: 0.3s ease transform;
  width: 23vw;
  height: 13px;
  background: #ccc;
  display: block;
  margin-bottom: 2px;
}

.illustration-pro .blinds-row:last-child {
  position: relative;
  opacity: 1;
}

// .illustration-pro .blinds-row:last-child::before {
//   content: "";
//   position: absolute;
//   top: 18px;
//   left: 50%;
//   margin-left: -10px;
//   width: 14px;
//   height: 14px;
//   // border-radius: 100%;
//   border: 3px solid $purple-color;
// }
// .illustration-pro .blinds-row:last-child::after {
//   content: "";
//   position: absolute;
//   bottom: -10px;
//   left: 50%;
//   margin-left: -1px;
//   width: 2px;
//   height: 10px;
//   background: #4C4F5A;
// }

.illustration-pro.public .blinds-rows {
  transform: translateY(-240px);
  transition: 1s ease transform;
}

.illustration-pro.public .blinds-rows::before,
.illustration-pro.public .blinds-rows::after {
  opacity: 0;
  transition: 1s ease opacity;
}

.illustration-pro.public .blinds-row {
  opacity: 0;
  transition: 2s ease opacity;
}

.illustration-pro.public .blinds-row:last-child {
  opacity: 1;
}


.illustration-pro.private .blinds-rows {
  transform: translateY(0);
  transition: 1s ease transform;
}

.illustration-pro.private .blinds-rows::before,
.illustration-pro.private .blinds-rows::after {
  opacity: 1;
}

.illustration-pro.private .blinds-row {
  opacity: 1;
  transition: 1s ease transform;
}

@media only screen and (max-width: 1023px) {
  .illustration-pro .blinds {
    height: 40vh;
  }

  .illustration-pro .blinds-top {
    height: 7px;
  }

  .illustration-pro .blinds-top::before {
    height: 7px;
  }

  .illustration-pro .blinds-row {
    height: 6px;
  }

  .illustration-pro .code {
    height: 40vh;
  }

  .illustration-pro .blinds-rows {
    width: 20vw;
    height: 40vh;
  }

  .illustration-pro .blinds-rows::before,
  .illustration-pro .blinds-rows::after {
    height: 40vh;
  }

  .illustration-pro .toggle {
    font-size: 1em;
  }

}