@import "../../../styles/common.styles.scss";

.webhomePage {
  min-height: 50vh;
  margin-bottom: 100px;
  overflow: hidden;

  .coming-soon {
    width: 50%;
    padding-top: 100px;
    padding-left: 25%;
    text-align: center;
  }
  .home-page-top-area {
    img {
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  .home-page-content-area {
    z-index: 2;
    background-color: #fff;
    position: relative;

    .home-page-logo {
      position: absolute;
      top: 10px;
      left: 20px;
      z-index: 9;
      width: 10vw;

      img {
        width: 100%;
      }
    }

    .topMainBar {
      @include gradient;
      height: 600px;
      width: 100%;
      overflow: hidden;
      position: relative;
      z-index: 0;

      .bottom-curve {
        position: absolute;
        margin-bottom: -10px;
        bottom: 0;

        img {
          width: 100vw;
        }
      }

      .middleArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
        position: absolute;
        left: 0;
        right: 0;

        .title {
          font-size: 1.5rem;
          letter-spacing: 5px;
          color: $yellow-color;
          margin-top: 5px;
          text-transform: uppercase;
        }

        .infoImage {
          padding-top: 0px;

          img {
            width: 130px;
          }
        }

        .tagLine {
          color: #ccc;
          letter-spacing: 1px;
          font-size: 1.2rem;
        }

        .try-buttons {
          margin-top: 30px;
          display: flex;
          justify-content: center;

          img {
            width: 20vw;
          }

          .tryFree {
            padding-top: 10px;
            margin: 10px 10px;

            span {
              display: inline-block;
              min-width: 220px;
              padding: 0px 10px;
              line-height: 40px;
              background: #fff;
              border-radius: 50px;
              text-align: center;
              position: relative;
              text-decoration: none;
              color: $blue-color;
              font-size: 15px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 2px;
            }

            span:hover {
              background-color: $yellow-color;
              cursor: pointer;
            }
          }
        }
      }

      .rocket {
        display: inline-flex;
        position: absolute;
        left: -50px;
        top: 100;
        margin-top: 150px;
        animation: rocketFly 6s linear infinite;

        img {
          width: 75px;
        }
      }

      .mainBarBottomArea {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 500px;

        img {
          margin: 0px 50px 0px 50px;
          width: 20vw;
        }

        .leftImage {
          position: absolute;
          bottom: 0;
          left: -40px;
          width: 30vw;
          z-index: -1;
        }

        .mobileImage {
          position: absolute;
          bottom: -100px;
          right: 0;
          z-index: -2;
        }

        .rightImage {
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          animation: MoveUpDown 4s linear infinite;
        }
      }
    }

    .site-stats {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      background-color: $purple-color;
      padding: 20px 0px;
      position: relative;

      .circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-top: -2px;
      }

      .circles li {
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background: rgba(255, 255, 255, 0.2);
        animation: animate-stats 25s linear infinite;
        bottom: -150px;
      }

      .circles li:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }

      .circles li:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
      }

      .circles li:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
      }

      .circles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
      }

      .circles li:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
      }

      .circles li:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
      }

      .circles li:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }

      .circles li:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
      }

      .circles li:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
      }

      .circles li:nth-child(10) {
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
      }

      .stat-value {
        color: $yellow-color;
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
        letter-spacing: 1px;

        div {
          color: #fff;
          text-transform: uppercase;
          font-size: 1rem;
          letter-spacing: 4px;
          z-index: 10;
        }

        .stats-icon {
          svg {
            font-size: 2rem;
            color: #fff;
          }
        }
      }
    }

    .contest-banner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include gradient;

      .contest-info {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $yellow-color;
        text-align: center;
        font-size: 3rem;
        letter-spacing: 1px;
        margin-top: 20px;

        div {
          color: #fff;
          font-size: 2rem;
        }

        .contest-button {
          font-size: 1rem;
          background-color: $yellow-color;
          color: $blue-color;
          font-weight: bold;
          padding: 3px 10px;
          border-radius: 10px;
          width: fit-content;
          margin: auto;
          margin-top: 10px;
        }

        .contest-button:hover {
          background-color: $purple-color;
          cursor: pointer;
          color: #fff;
        }
      }

      .contest-image {
        width: 30%;
        img {
          width: 100%;
        }
      }
    }

    .showcase-section {
      min-height: 200px;
      position: relative;
      background-color: #efefff;
      padding: 20px 0px 10px 0px;
      margin-top: 30px;

      .showcase-title {
        font-size: 1.5rem;
        color: $purple-color;
        margin-bottom: 20px;
        letter-spacing: 1px;
        font-weight: bold;

        .showcase-sub-title {
          font-size: 0.8rem;
          color: $gray-color;
        }
      }

      .showcase-page-link {
        color: $blue-color;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 20px;
        margin-bottom: 20px;

        .view-all-link {
          text-decoration: none;
          color: $blue-color;
        }

        .view-all-link:hover {
          cursor: pointer;
          color: $purple-color;
        }
      }

      .redeem-item {
        padding: 20px 0px;
        margin: 10px;
        border-radius: 10px;
        background-color: #fff;
        color: $blue-color;
        letter-spacing: 1px;
        margin-bottom: 40px;
        @include shadow;

        .redeem-item-value {
          color: $purple-color;
          font-size: 0.8rem;
          letter-spacing: 1px;
        }

        img {
          width: 100%;
        }
      }

      .top-curve {
        img {
          width: 100%;
          position: absolute;
          left: 0;
          top: -5px;
        }
      }

      .bottom-curve {
        img {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }

    .infoSection {
      width: 100%;
      padding-bottom: 10px;
      // display: flex;
      // justify-content: space-around;
      // border-bottom: 1px solid #ddd;
      // max-width: 100vw;
      // overflow: hidden;
      // background-color: #fff;
      // width: 100vw;
    }

    .contest-section {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .request-button {
      cursor: pointer;
      color: $green-color;    
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 1.2rem;
      margin-top: 10px;
      text-decoration-line: underline;  
    }

    .subject-list {
      .grade-title {
        font-size: 1rem;
        letter-spacing: 1px;
        color: $purple-color;
        text-align: left;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
        position: relative;
        margin-top: 45px;
        padding-left: 10px;

        img {
          position: absolute;
          width: 75px;
          right: 20px;
          bottom: 0;
        }

        .quote-string {
          font-size: 1rem;
          font-weight: normal;
          letter-spacing: 1px;
          color: $gray-color;
          text-transform: none;
          // font-style: italic;
        }
      }
    }

    .sectionHeading {
      @include section-heading;
      font-size: 2rem;
      letter-spacing: 1px;
      color: $purple-color;
      text-align: center;
      display: flex;
      justify-content: flex-start;
      line-height: 10px;
      margin-bottom: 50px;
      margin-top: 50px;
      margin-left: 70px;
      align-items: baseline;
      text-transform: none;
      font-weight: normal;

      div {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 5px 10px;
        border-radius: 50px;
      }

      img {
        width: 50px;
        margin-right: 5px;
      }
    }

    // Media queries
    @media only screen and (max-width: 767px) {
      /* For mobile phones: */

      .home-page-logo {
        width: 25vw !important;
        left: 0;
        right: 0;
        margin: auto;
      }

      .topMainBar {
        height: 70vh;

        .middleArea {
          padding: 10px 5vw;

          .title {
            font-size: 1rem;
          }

          .tagLine {
            font-size: 0.8rem;
          }

          .infoImage {
            img {
              width: 20vw;
            }
          }
        }
      }

      .rocket {
        margin-top: 50px !important;

        img {
          width: 10vw !important;
        }
      }

      .mainBarBottomArea {
        .leftImage {
          display: none !important;
        }

        .rightImage {
          display: none !important;
        }

        .mobileImage {
          position: absolute;
          bottom: -100px;
          right: 0;
          z-index: -2;
          display: flex;
          width: 100%;
          justify-content: center;

          img {
            width: 50vw;
          }
        }
      }

      .try-buttons {
        img {
          width: 30vw !important;
        }
      }

      .site-stats {
        .stat-value {
          font-size: 1rem;

          div {
            font-size: 0.8rem;
            letter-spacing: 1px;
            font-weight: normal;
          }

          .stats-icon {
            svg {
              font-size: 1.5rem;
            }
          }
        }
      }

      .contest-banner {
        .contest-info {
          font-size: 0.8rem;
          margin-top: 5px;

          div {
            font-size: 0.6rem;
          }

          .contest-button {
            font-size: 0.6rem;
            padding: 2px 7px;
            margin-top: 5px;
          }
        }
      }
    }

    // Media queries
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .contest-banner {
        .contest-info {
          font-size: 1rem;
          margin-top: 15px;

          div {
            font-size: 0.8rem;
          }

          .contest-button {
            font-size: 0.8rem;
            padding: 2px 7px;
            margin-top: 10px;
          }
        }
      }
    }

    // Animations

    @keyframes MoveUpDown {
      0%,
      100% {
        transform: translateY(0px);
      }

      50% {
        transform: translateY(-100px);
      }
    }

    @keyframes MoveLeftRight {
      0%,
      100% {
        transform: translateX(0px) rotate(-25deg);
      }

      50% {
        transform: translateX(-180px) rotate(25deg);
      }
    }

    @keyframes animate-stats {
      0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
      }

      100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
      }
    }

    @keyframes rocketFly {
      0%,
      100% {
        opacity: 1;
      }

      100% {
        transform: translateY(-250px) translateX(400px) rotate(20deg);
      }
    }

    .whats-app:hover {
      cursor: pointer;
    }
  }
}
