@import "../../styles/common.styles.scss";

.institutionContainer {
  @include page-container;
  padding: 20px;

  .institutionFormContainer {
    @include shadow;
    margin: 10px 5px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;

    .institutionTitle {
      @include main-heading;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }

    .institutionForm {
      display: flex;
      width: 100%;
      img {
        width: 25%;
      }
      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .formField {
          min-width: 30%;
          text-align: left;
        }

        .institution-image {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 80%;
          }
        }
      }
    }
    .sendButton {
      text-align: center;
      padding-top: 30px;
      Button {
        background-color: $purple-color;
        letter-spacing: 1px;
      }
    }

    .institutionInfo {
      display: flex;
      justify-content: space-around;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      margin-top: 20px;
      color: $blue-color;
      span {
        vertical-align: top;
        color: $purple-color;
      }
    }
  }
}

.error-text {
  color: $red-color;
  font-size: small;
}

// Media queries
@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .institutionContainer {
    .institutionFormContainer {
      margin: 20px 0;
      margin-left: 20px;
    }
    .institutionInfo {
      flex-direction: column;
    }
  }
}
