@import '../../../../styles/common.styles.scss';

.my-story-form-page {
  background-color: #6d3ea2;
  min-height: 100vh;
  width: 100vw;

  .my-story-form-top-image {
    width: 100%;
    height: 30vh;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .my-story-form-title {
    text-align: center;
    color: yellow;
    font-size: 1.2rem;
    margin: 10px 0px;
    letter-spacing: 1px;
  }

  .my-story-form-summary {
    padding: 10px;
    color: #fff;
    letter-spacing: .5px;
    font-size: .8rem;
    text-align: center;
  }

  .my-story-form-container {
    width: 100vw;
    padding: 10px;
    margin-top: 20px;

    .my-story-form-character-name {
      width: 100vw;
      padding-left: 10vw;

      .MuiInput-formControl {
        width: 80vw;
        color: #fff !important;
      }

      .MuiFormLabel-root {
        color: #fff !important;
      }

      .MuiInput-underline {
        border-bottom: 2px solid yellow !important;
      }
    }

    .my-story-form-button {
      text-align: center;
      margin-top: 50px;

      span {
        padding: 5px 10px;
        background-color: yellow;
        border: 2px solid #fff;
        border-radius: 50px;
        font-size: 1.2rem;
        letter-spacing: 1px;
        @include shadow;
        color: #6d3ea2;
      }
    }
  }
}