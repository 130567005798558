@import "../../../styles/common.styles.scss";

.user-profile-container {
  width: 100%;

  .profile-card {
    padding: 2vw;
    margin-bottom: 30px;
    min-height: 350px;
    width: 100%;
    text-align: center;

    .profile-edit {
      // display: flex;
      align-items: center;

      .profile-image {
        text-align: center;
        padding-bottom: 0px;
        overflow: hidden;

        img {
          width: 13vw;
        }
      }
      .profile-title {
        font-size: 1rem;
        padding-left: 10px;
        letter-spacing: 1px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        color: $purple-color;
        text-align: center;
      }
      .profile-button {
        color: #fff;
        padding-bottom: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 0.8rem;

        span {
          background-color: $purple-color;
          padding: 5px 10px;
          border-radius: 20px;
          letter-spacing: 1px;
        }

        span:hover {
          background-color: $yellow-color;
          color: $purple-color;
          border: 1px solid $purple-color;
          cursor: pointer;
        }
      }

      .edit-btn {
        float: right;
      }

      .delete-btn {
        float: left;
      }
    }

    .top-area {
      border-top: 1px solid #ccc;
      margin-top: 40px;
      .profile-info {
        padding-left: 5px;

        .profile-info-label {
          margin-top: 20px;
          font-size: 0.8rem;
          letter-spacing: 1px;
          color: $blue-color;
        }

        .profile-info-value {
          font-size: 0.8rem;
          letter-spacing: 1px;
          color: #555;
        }
      }
    }
  }
}

.profile-show-error {
  text-align: center;
  margin-top: 50px;
  letter-spacing: 1px;
}
