
.Title .quoteString{
  color: #aaa;
    font-size: .8rem;
    margin-top: 1rem;
}

.topImage{
  display: flex;
  // // background-color: #fdc453;
  // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1600' height='350' preserveAspectRatio='none' viewBox='0 0 1600 350'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1082%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c263 C 106.8%2c229.6 320.4%2c102.2 534%2c96 C 747.6%2c89.8 854.8%2c223.2 1068%2c232 C 1281.2%2c240.8 1493.6%2c158.4 1600%2c140L1600 350L0 350z' fill='rgba(253%2c 196%2c 83%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1082'%3e%3crect width='1600' height='350' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  img{
    width: 100%;
  }

  .imageLeft{
    animation: MoveUpDown 6s linear infinite;
    left: 0;
    bottom: 0;
  }
}



    
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
}