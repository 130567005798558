@import '../../styles/common.styles.scss';

.stories-listing-page {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  overflow-y: hidden;
  @include plainBackground;


  .stories-listing-page-top {
    width: 100vw;
    overflow: hidden;
    position: absolute;

    img {
      width: 100%;
    }
  }

  .top-animation {
    text-align: center;
    margin-top: 0vh;
    margin-bottom: 2vh;

    .stories-page-top-animation {
      height: 30vh;
    }
  }

  .stories-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: -20px;
    margin-bottom: 60px;


    .story-card {
      width: 45vw;
      height: 30vw;
      margin-bottom: 7vw;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      position: relative;
      @include shadow;

      .story-card-image {
        height: 23vw;
        overflow: hidden;

        img {
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .story-title {
        height: 7vw;
        font-size: .9rem;
        letter-spacing: .5px;
        text-align: center;
        padding: 5px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}