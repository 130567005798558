@import '../../../styles/common.styles.scss';

.games-listing {

  .game-card {
    background-color: #fff;
    border-radius: 10px;
    margin: 15px 10px 20px 10px;
    @include shadow;

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .game-name {
      color: $purple-color;
      letter-spacing: 1px;
      padding: 0px 1px;
    }

    .game-tag {
      font-size: .8rem;
      color: $gray-color;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }

  }

  .game-card:hover {
    cursor: pointer;
    animation: box-move-up .5s ease forwards;
  }
}

// Media queries
@media only screen and (max-width: 1023px) {
  .games-listing {
    .game-card {

      .game-name {
        font-size: .9rem;
      }

      .game-tag {
        font-size: .7rem;
      }
    }
  }
}