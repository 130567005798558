$primary-color: #862c92;

$blue-color: #3e4bbb;
$purple-color: #862c92;
$yellow-color: #ffcd24;
$red-color: #ff6961;
$green-color: #87b76f;
$gray-color: #777;
$black-color: #000;

@mixin section-heading {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $purple-color;
}

@mixin main-heading {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $blue-color;
}

@mixin shadow {
  box-shadow: 5px 5px 15px 0 rgba(46, 61, 73, .2);
}

@mixin backgroundStyle {
  background: linear-gradient(rgba(134, 44, 146, .1), rgba(134, 44, 146, .1)), url('https://www.cudents.com/prod/assets/images/homepage/doodle1_bg.webp');
}

@mixin plainBackground {
  background: rgba(134, 44, 146, .1);
}

@mixin gradient {
  background: linear-gradient(to bottom, #001540 0%, #570861 99%);
}

@mixin page-container {
  background-color: #efefff;
  padding-top: 20px;
  min-height: 60vh;
  padding-bottom: 100px;
}



@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bottom-top {
  100% {
    margin-top: 0px;
  }
}

@keyframes top-bottom {
  100% {
    margin-top: calc(100vh - 5vw);
    margin-left: 0vw;
  }
}

@keyframes bottom-top-fade {
  0% {
    opacity: 1;
    margin-top: 42vw;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    margin-top: 0px;
  }
}

@keyframes left-right {
  100% {
    left: 100vw;
  }
}

@keyframes right-left {
  100% {
    right: 100vw;
  }
}

@keyframes left-right-left {
  0% {
    transform: rotate(0);
    left: -9vw;
  }

  50% {
    transform: rotate(30deg);
    left: -4vw;
  }

  100% {
    transform: rotate(0);
    left: -9vw;
  }
}

@keyframes flip {
  0% {
    transform: rotateX(0);
    animation-timing-function: ease-out;
  }

  25% {
    transform: translateZ(150px) rotateX(90deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: translateZ(150px) rotateX(180deg);
    animation-timing-function: ease-in;
  }

  75% {
    transform: rotateX(270deg);
    animation-timing-function: ease-in;
  }

  100% {
    transform: rotateX(360deg);
    animation-timing-function: ease-in;
  }
}


@keyframes flip-side {
  0% {
    transform: rotateY(0);
    animation-timing-function: ease-out;
  }

  20% {
    transform: translateZ(150px) rotateY(90deg);
    animation-timing-function: ease-out;
  }

  40% {
    transform: translateZ(150px) rotateY(180deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: rotateY(270deg);
    animation-timing-function: ease-in;
  }

  80% {
    transform: rotateY(360deg);
    animation-timing-function: ease-in;
  }
}

@keyframes gelatine {

  from,
  to {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1.05, 1);
  }

  50% {
    transform: scale(1.1, 1);
  }

  75% {
    transform: scale(1.05, 1);
  }
}

@keyframes dance {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}



@keyframes wiggle {

  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.25);
  }

  20% {
    transform: scale(1.25) rotate(-5deg);
  }

  25% {
    transform: scale(1.25) rotate(5deg);
  }

  30% {
    transform: scale(1.25) rotate(-3deg);
  }

  35% {
    transform: scale(1.25) rotate(2deg);
  }

  40% {
    transform: scale(1.25) rotate(0);
  }
}


@keyframes zoom-card {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.2, 1.2);
  }
}


@keyframes box-move-up {
  100% {
    margin-top: 15px;
    box-shadow: none;
    background-color: $yellow-color;
  }
}

.total-count {
  min-width: 150px;
  margin: 10px;
  vertical-align: bottom;
  display: inline-block;

  .total-label {
    font-size: 1.2rem;
    color: $purple-color;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .total-value {
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    letter-spacing: 1px;
  }
}

.coming-soon {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 75vw;
    margin-top: -30vh;
  }
}



.premium-icon{
  position: absolute;
  top: 1vw;
  right: 1vw;
  background: rgba(0,0,0,.8);
  border-radius: 10vw;
  width: 6vw;
  height: 6vw;
  padding: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 6vw !important;
  }
}