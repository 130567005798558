@import '../../../styles/common.styles.scss';

.user-dashboard-left-area {
  margin-top: 50px;
  height: 100%;

  .dashboard-left-top-area {

    .dashboard-menu-image {
      height: 20vh;
      background-color: $yellow-color;
      margin-bottom: 10px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .dashboard-left-menu {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .dashboard-menu-item {
        text-align: center;
        margin: 20px 0px;
        width: 27vw;
        border-radius: 10px;
        letter-spacing: .5px;
        color: $primary-color;
        .dashboard-menu-item-icon {
          img {
            width: 13vw;
          }
        }
      }

      .MuiListItemIcon-root {
        margin-right: 5px;

        img {
          width: 32px;
        }
      }

      .MuiListItemText-root {
        color: $gray-color;

        span {
          font-family: 'Poppins', sans-serif !important;
          -webkit-font-smoothing: antialiased !important;
          -moz-osx-font-smoothing: grayscale !important;
        }
      }

      .active-menu {
        background-color: $purple-color !important;

        .MuiListItemText-root {
          color: #fff;
        }
      }
    }
  }

  .user-dashboard-suggestion {
    margin-top: 20px;
    padding: 10px;

    .suggestion-title {
      color: $blue-color;
      font-weight: bold;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }

    .suggestion-quiz {
      img {
        width: 100%;
      }

      .suggestion-quiz-name {
        color: $purple-color;
        letter-spacing: 1px;
      }
    }

    .suggestion-quiz:hover {
      cursor: pointer;
    }
  }

  .user-dashboard-image{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 30px;
    img{
      width: 50vw;
    }
  }
}

.horizontal-container {
  min-height: 1vh;
  height: auto;
  width: 100vw;
  margin-bottom: 20px;

  .MuiList-root {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}



// Media queries
@media only screen and (max-width: 767px) {

  .user-dashboard-left-area {
    .dashboard-left-top-area {
      .dashboard-left-user {
        display: flex;
        justify-content: space-evenly;

        .dashboard-left-wish-image {
          img {
            width: 50px;
          }
        }
      }
    }

    .dashboard-left-menu {
      .MuiListItemIcon-root {
        img {
          width: 20px !important;
        }
      }

      .MuiListItemIcon-root {
        min-width: 24px !important;
      }

      .MuiListItemText-root {
        span {
          font-size: .8rem !important;
          letter-spacing: 1px;
        }
      }
    }
  }
}




// Media queries
@media only screen and (max-width: 1023px) {

  .user-dashboard-left-area {
    .dashboard-left-top-area {
      .dashboard-left-user {
        display: flex;
        justify-content: space-evenly;

        .dashboard-left-wish-image {
          img {
            width: 50px;
          }
        }
      }
    }
  }
}