@import '../../styles/common.styles.scss';

.support-container {
  min-height: 100vh;
  @include plainBackground;
  .video-title{
    font-size: 1.2rem;
    letter-spacing: 1px;
    color:$gray-color;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    margin: 15px;
  }
  .video-section {
    text-align: center;
  }
  .support-image {
    margin-top: 100px;

    img {
      width: 100%;
    }
  }

  .support-container-content {
    min-height: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;

    .Title {
      @include section-heading;
      padding: 10px;
      text-transform: none;
    }

    .faqSection {
      @include shadow;
      padding: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      margin-left: 30px;
      background-color: #fff;

      .TitleSmall {
        color: $blue-color;
        text-align: left;
      }

      .faqList {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-top: 30px;

        .MuiAccordion-root {
          box-shadow: none;
        }

        .heading {
          flex-shrink: 0;
          font-weight: bold;
          letter-spacing: 1px;
          color: $purple-color;
          text-align: left;
          font-size: 1rem;
        }

        .deleteIcon {
          color: rgb(173, 0, 0);
        }

        .faqAnswers {
          letter-spacing: 1px;
          color: #555;
          text-align: justify;
          font-size: 1rem;
        }
      }
    }
  }
}


// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .support-container {
    .support-container-content {
      .faqSection {
        font-size: .5rem !important;
      }
    }
  }
}