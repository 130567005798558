  @import '../../../../styles/common.styles.scss';

  .save-the-earth-game-box {
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 0;
    user-select: none;

    .save-the-earth-game-background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }

    }

    .game-timer-section {
      position: absolute;
      background-color: 0px;
      right: 5px;
      bottom: 5px;
      width: fit-content;
      display: inline-block;
    }



    .game-level-number {
      position: absolute;
      left: 5px;
      bottom: 5px;
      background-color: $yellow-color;
      padding: 2px 5px;
      border-radius: 5px;
    }

    .game-question-section {
      position: absolute;
      border-radius: 10px;
      padding: 5px;
      left: 25vw;
      top: 15vw;
      width: 50vw;
      font-size: 2rem;
      color: #fff;
      animation-delay: 3s;
      background-color: rgba(0, 0, 0, .7);
      animation: move-left-up 2s ease forwards;
      animation-delay: 3s;

      .game-question {
        padding: 5px 20px;
        letter-spacing: 4px;
        border-radius: 10px;
      }
    }

    .answer-out {
      position: absolute;
      left: 0;
      right: 0;
      top: 20vh;
      margin: auto;
      z-index: 999;

      img {
        height: 50vh;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .bonus-message {
        background-color: rgba(255, 155, 0, .9);
        width: 60%;
        margin-left: 20%;
        color: black;
        padding: 5px;
        letter-spacing: 2px;
        font-size: 1.2rem;
        border-radius: 10px;
        font-weight: bold;
      }

      .game-button {
        background-color: $yellow-color;
        padding: 5px 10px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 10px;
        font-size: 1.5rem;
        letter-spacing: 1px;
        border: 3px solid #000;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .game-button:hover {
        cursor: pointer;
        background-color: $purple-color;
        color: #fff;
      }
    }



    .meteor-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      z-index: 2;

      .game-car-1 {
        position: absolute;
        left: -10vw;
        bottom: 2px;
        animation: left-right 15s ease;
        animation-timing-function: linear;
        animation-delay: 3s;

        img {
          width: 7vw;
        }
      }

      .game-car-2 {
        position: absolute;
        right: -10vw;
        bottom: 2px;
        animation: right-left 15s ease;
        animation-timing-function: linear;
        animation-delay: 2s;

        img {
          width: 7vw;
        }
      }

      .game-bike-1 {
        position: absolute;
        right: -10vw;
        bottom: 2px;
        animation: right-left 15s ease;
        animation-timing-function: linear;
        animation-delay: 4s;
        z-index: -1;

        img {
          width: 7vw;
        }
      }

      .game-bike-2 {
        position: absolute;
        right: -10vw;
        bottom: 2px;
        animation: right-left 15s ease;
        animation-timing-function: linear;
        animation-delay: 15s;
        z-index: -1;

        img {
          width: 7vw;
        }
      }

      .game-aero-1 {
        position: absolute;
        right: -10vw;
        top: 5vw;
        animation: right-left 15s ease;
        animation-timing-function: linear;
        animation-delay: 11s;
        z-index: -1;

        img {
          width: 7vw;
        }
      }

      .blast-1 {
        position: absolute;
        left: 3vw;
        bottom: 3vw;
        animation: zoom-in-zoom-out .5s ease;
        animation-timing-function: linear;

        img {
          width: 23vw;
        }
      }

      .blast-2 {
        position: absolute;
        left: 80vw;
        bottom: 3vw;
        transform: scale(0, 0);
        animation: zoom-in-zoom-out .5s ease forwards;
        animation-timing-function: linear;
        animation-delay: 1s;

        img {
          width: 23vw;
        }
      }

      .blast-3 {
        position: absolute;
        left: 60vw;
        bottom: 3vw;
        transform: scale(0, 0);
        animation: zoom-in-zoom-out .5s ease forwards;
        animation-timing-function: linear;
        animation-delay: .5s;

        img {
          width: 23vw;
        }
      }

      .blast-4 {
        position: absolute;
        left: 30vw;
        bottom: 3vw;
        transform: scale(0, 0);
        animation: zoom-in-zoom-out .5s ease forwards;
        animation-timing-function: linear;
        animation-delay: 1.5s;

        img {
          width: 23vw;
        }
      }


      .meteor {
        position: relative;
        z-index: 100;
        left: 10px;
        margin-top: -7vw;
        margin-left: 25vw;
        opacity: 1;
        color: #000;
        line-height: 4vw;
        vertical-align: middle;

        img {
          width: 5vw;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          border: none !important;
          z-index: -1;
        }

        img:hover {
          cursor: pointer;
        }

        .meteor-value {
          font-size: .9rem;
          font-weight: bold;
          letter-spacing: 1px;
          z-index: 9;
          display: initial;
          background-color: rgba(255, 255, 255, .7);
          padding: 2px 7px;
          border-radius: 10px;
          position: absolute;
          padding: 0px;
          line-height: 2;
          min-width: 10vw;
        }

        .meteor-value:hover {
          cursor: pointer;
        }
      }

      .meteor:hover {
        cursor: pointer;
        opacity: 1;
        color: $purple-color;
      }

      .meteor-delay-1 {
        animation: top-bottom 27s ease forwards;
        animation-timing-function: linear;
        animation-delay: 3s;
      }

      .meteor-delay-2 {
        animation: top-bottom 30s ease forwards;
        animation-timing-function: linear;
        animation-delay: 1s;
      }

      .meteor-delay-3 {
        animation: top-bottom 28s ease forwards;
        animation-timing-function: linear;
        animation-delay: 2s;
      }

      .meteor-delay-4 {
        animation: top-bottom 27s ease forwards;
        animation-timing-function: linear;
        animation-delay: 4s;
      }

      .random-meteor {
        animation: bottom-top-fade 30s ease forwards;
        animation-timing-function: linear;
        text-align: left;
        margin-left: 15vw;
      }

      .random-meteor-1 {
        animation: bottom-top-fade 30s ease forwards;
        animation-timing-function: linear;
        animation-delay: 3s;
        text-align: left;
        margin-left: -35vw;
      }
    }

    .start-screen {
      width: 100%;
      height: 80%;
      margin-top: 5%;
      background-color: rgba(0, 0, 0, .8);
      text-align: center;
      letter-spacing: 1px;
      font-size: 2rem;
      color: #fff;

      .start-screen-message {
        font-family: 'Patrick Hand', cursive;
        padding: 10px;
      }

      img {
        width: 10vw;
      }

      button {
        font-size: 1.5rem;
        background-color: $yellow-color;
        border-radius: 20px;
        padding: 5px 10px;
        letter-spacing: 1px;
        margin-top: 30px;
      }

      button:hover {
        cursor: pointer;
        background-color: $red-color;
      }
    }

    .music-switch {
      margin-top: 20px;
      font-size: 1rem;
    }

  }


  @keyframes move-left-up {

    100% {
      left: 5px;
      top: 5px;
      font-size: 1rem;

    }
  }

  @keyframes move-down-se {

    100% {
      right: 10px;
    }
  }



  // Media queries
  @media only screen and (max-width: 1023px) {

    /* For mobile phones: */
    .save-the-earth-game-box {

      .game-level-number {
        font-size: .8rem;
      }

      .start-screen {
        font-size: 1rem;
        width: 100vw;
        margin-left: 0;
        border-radius: 0px;

        button {
          margin-top: 10px;
          font-size: .8rem;
        }

        .music-switch {
          margin-top: 5px;
          font-size: 1rem;
        }
      }

      .answer-out {
        img {
          height: 50vh;
        }

        .game-button {
          font-size: 1rem;
        }
      }

      .meteor-container {
        .meteor {
          .meteor-value {
            font-size: .8rem;
          }
        }

        .blast-1 {
          bottom: 5vw;
        }

        .blast-2 {
          bottom: 5vw;
        }

        .blast-3 {
          bottom: 5vw;
        }

        .blast-4 {
          bottom: 6vw;
        }
      }
    }
  }