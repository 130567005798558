@import "../../styles/common.styles.scss";

.group-main-page {
    @include backgroundStyle;
    min-height: 100vh;
    padding-top: 20px;

    .group-page-top {
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;

        img {
            width: 100vw;
        }
    }
    .group-image {
        text-align: center;
        img {
            width: 15%;
        }
    }
    .empty-msg {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        color: $blue-color;
        margin: 100px 10px;
        text-align: center;
    }

    .history-button {
        text-align: center;
        position: absolute;
        top: 10px;
        right: 10px;

        span {
            background-color: #fff;
            color: $primary-color;
            padding: 3px 5px;
            border-radius: 10px;
            @include shadow;
        }

        span:hover {
            cursor: pointer;
        }
    }
    .group-detail {
        vertical-align: top;
        text-align: center;
        .main-title {
            font-size: 1.2rem;
            letter-spacing: 0.5px;
            color: $blue-color;
            margin: 0 10px;
            text-align: center;
        }
        .sub-title {
            font-size: 0.9rem;
            letter-spacing: 0.5px;
            color: $primary-color;
            margin: 0 10px;
        }
        .group-button {
            width: 15vw;
            color: #fff;
            letter-spacing: 1px;
            background-color: $red-color;
            margin: 5px 10px;
            padding: 0px 10px;
            border-radius: 30px;
            text-align: center;
            &:hover {
                cursor: pointer;
                background-color: $blue-color;
                color: #fff;
            }
        }
        .edit-button {
            width: 10vw;
            background-color: $purple-color;
        }
    }
}
