.avatarDialogContainer {
  text-align: center;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;

  .closeButton {
    width: 100%;
    text-align: right;
    margin-bottom: 10px;

    svg {
      font-size: 2rem;
      color: #999;
      cursor: pointer;
    }
  }

  .gridList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .gridImage {
      cursor: pointer;

      img {
        width: 18vw;
      }
    }

    .gridImage:hover {
      background-color: #ccc;
    }
  }

  .avatar-cancel-button {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }
}