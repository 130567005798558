@import "../../styles/common.styles.scss";

.whatsapp-main {
  @include shadow;
  margin: 20px;
  padding: 15px;
  border-top: 10px solid $purple-color;
  background-color: #fff;
  border-radius: 10px;
  height: 80vh;
  .whatsapp-title {
    @include main-heading;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.controlBox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  .wp-box {
    width: 500px;
  }
  .admin-part-control {
    min-width: 350px;
    margin: 10px;
    vertical-align: bottom;
  }
  .sendButton {
    color: #fff;
    vertical-align: middle;
    font-style: normal;
  }
}
