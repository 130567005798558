@import '../../../styles/common.styles.scss';

.level-listing-page {
  min-height: 100vh;
  @include plainBackground;

  .levelListHeader-listing-page {
    width: 100vw;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #fff;
    background-color: $purple-color;
  }

  .levelList {
    width: 85%;
    margin: 0 auto;
    margin-top: 30px;
  }
}

// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .level-listing-page {
    .levelListHeader {
      width: 95vw;
      font-size: .8rem;
    }
  }
}