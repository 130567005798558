.game-timer {
  background-color: rgba(0, 0, 0, .8);
  padding: 5px;
  border-radius: 10px;

  .timer-time {
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 1px;
  }
}

// Media queries
@media only screen and (max-width: 850px) {

  /* For mobile phones: */
  .game-timer {
    width: fit-content;

    .timer-time {
      font-size: .8rem;
    }
  }
}