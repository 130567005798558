.top-carousel {
  width: 100%;

  .top-carousel-image {
    width: 90vw;
    height: 45vw;
    overflow: hidden;
    margin: 0 5vw;
    border-radius: 1vh;

    img {
      width: 90vw;
    }
  }
}