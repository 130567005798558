@import '../../../../styles/common.styles.scss';

.game-console-fire-container {
  width: 100vw;
  height: 100vh;
  background-color: $primary-color;
  ;

  .game-console-fire-controls-left {
    position: absolute;
    text-align: center;
    width: 10vw;
    left: 2.5vw;
    bottom: 20px;

    .game-console-fire-left-button-fire {
      img {
        width: 9vw;
        margin-bottom: 10px;
      }
    }

    .game-console-fire-left-button-move {
      img {
        width: 9vw;
        margin-bottom: 10px;
      }
    }
  }

  .game-console-fire-controls-right {
    position: absolute;
    text-align: center;
    width: 10vw;
    right: 2.5vw;
    bottom: 20px;

    .game-console-fire-right-button-fire {
      img {
        width: 9vw;
        margin-bottom: 10px;
      }
    }

    .game-console-fire-right-button-move {
      img {
        width: 9vw;
        margin-bottom: 10px;
      }
    }
  }

  .game-console-fire-play-area {
    width: 70vw;
    height: 100vh;
    margin-left: 15vw;
    background-color: blueviolet;
    position: relative;

    .game-console-fire-background {
      width: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;

      /*adjust s value for speed*/
      img {
        width: 100%;
      }

    }


    .background-animation {
      animation: game-console-animatedBackground 50s linear infinite;
    }

    @keyframes game-console-animatedBackground {
      0% {
        transform: translate(0, 0);
      }

      100% {
        transform: translate(0, 66%);
      }
    }

    .game-console-fire-current-score {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      font-weight: bold;
      font-size: 1.2rem;
      color: #fff;
      text-shadow: 2px 2px #000000;
      align-items: center;
      display: flex;

      img {
        width: 24px;
        margin-right: 5px;
      }
    }

    .game-console-fire-lives {
      position: absolute;
      top: 10px;
      width: 100%;
      display: flex;
      justify-content: end;
      z-index: 1;
      align-items: center;

      .game-console-fire-lives-image {
        width: 30px;
      }
    }

    .game-console-fire-obstacle-container {
      width: 100%;
      height: 100%;
      position: relative;

      .game-console-fire-obstacle-item {
        width: 10vw;
        height: 10vw;
        text-align: center;
        position: absolute;
        animation: game-console-fire-obstacle-movement 15s linear;

        img {
          width: 10vw;
        }

        .bonus-point {
          left: 200px;
          bottom: 200px;
          animation: bonus-point 1s linear;

          img {
            width: 32px;
          }
        }

        @keyframes bonus-point {
          from {
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            transform: rotateX(0deg);
            bottom: 200px;
          }

          to {
            -webkit-transform: rotateX(720deg);
            -moz-transform: rotateX(720deg);
            transform: rotateX(720deg);
            bottom: 300px;
          }
        }
      }

      @keyframes game-console-fire-obstacle-movement {
        0% {
          top: -11vw;
        }

        100% {
          top: calc(100vh + 200px);
        }
      }



    }

    .game-console-fire-gun {
      width: 10vw;
      position: absolute;
      bottom: 0;
      text-align: center;
      z-index: 2;

      img {
        width: 7vw;
        min-height: 5vw;
      }
    }


    .game-console-fire-bullet {
      position: absolute;
      width: 10vw;
      height: 30px;
      text-align: center;
      animation: game-console-fire-move-bullet 2s linear;
      z-index: 1;

      img {
        height: 30px;
      }
    }

    @keyframes game-console-fire-move-bullet {
      0% {
        bottom: 0vh;
      }

      100% {
        bottom: 110vh;
      }
    }
  }


  .game-console-start-game-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .game-console-scoreboard-image {
      position: relative;
      text-align: center;

      img {
        height: 90vh;
      }

      .game-console-scorecard-info {
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 1px;
        height: 60vh;

        img {
          width: 7vw;
          height: auto;
          margin-top: 30px;
        }

        .game-console-scorecard-title {
          font-family: 'Comic Neue';
        }

        .game-console-game-info {
          width: 100%;

          img {
            width: 50vh;
          }
        }

        .game-console-scores {
          width: 70%;
          font-size: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .game-console-play-button {
      text-align: center;

      img {
        height: 20vh;
        margin-top: -20vh;
      }
    }
  }

}