@import '../../styles/common.styles.scss';

.order-summary-page {
    @include page-container;
    @include plainBackground;
    min-height: 100vh;

    .order-summary-container {
        margin-left: 20px;

        .wrapper {
            width: 100%;

            .payments {
                display: flex;
                justify-content: center;
                letter-spacing: 1px;
                margin: 20px 0px;


                .payments-form {
                    width: 70%;

                    .line-break {
                        margin: 5px 0;
                        height: 5px;
                        border-bottom: 1px solid #ccc;
                    }

                    .payments-title {

                        font-size: 1.5rem;
                        font-weight: bold;
                        color: $primary-color;
                        text-align: center;
                        margin-bottom: 30px;
                        margin-top: 10px;
                        border-bottom: 1px solid #ccc;
                    }

                    .payments-success {
                        width: 100%;
                        justify-content: center;
                        font-size: 1.2rem;
                        font-weight: bold;
                        color: $green-color;
                        text-align: center;

                        img {
                            width: 50%;
                        }
                    }

                    .purchaseButtonContainer {
                        text-align: center;

                        .purchaseButton {
                            background-color: #004AAD;
                            width: 200px;
                            margin: 20px;
                            padding: 10px;
                            color: #ffffff;
                            font-weight: bold;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            border-radius: 5px;
                        }

                        .redirect-button {
                            background-color: $purple-color;
                            color: #fff;
                            letter-spacing: 1px;
                            margin: 20px 5px;
                        }
                    }

                    .apply-coupon {
                        text-align: center;
                        color: #004AAD;
                        text-decoration: underline;
                        cursor: pointer;
                        letter-spacing: 1px;
                        font-weight: bold;
                        margin-top: 10px;
                    }

                    .formLabel {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 10px;
                        letter-spacing: 1px;

                        .paymentLabel {
                            font-size: 1rem;
                            width: 70%;
                            color:$gray-color;
                        }

                        .paymentValue {
                            width: 30%;
                            text-align: right;
                            color: #333;
                        }
                    }

                    .formField {
                        Button {
                            vertical-align: bottom;
                        }

                        .error-msg {
                            color: #B00020;
                            cursor: pointer;
                            letter-spacing: 1px;
                            font-weight: bold;
                        }
                    }


                    .coupon-code {
                        text-align: center;
                        margin-top: 10px;

                        div {
                            margin-right: 10px;
                        }

                        button {
                            margin-top: 20px;
                            background-color: $purple-color;
                        }
                    }


                }
            }
        }
    }

    .order-summary-mobile {
        .wrapper {
            .payments {
                .payments-form {
                    width: 95%;
                }
            }
        }
    }
}