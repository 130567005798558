@import '../../../styles/common.styles.scss';

.dailygk-listing-page {
  padding-top: 60px;
  min-height: 100vh;
  @include plainBackground;
  position: relative;


  .top-animation {
    position: absolute;
    top: 50px;
    display: flex;
    justify-content: center;
    width: 100%;

    .dailygk-page-top-animation {
      height: 35vw;
    }
  }

  .dailygk-history-button {
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      background-color: #fff;
      color: $primary-color;
      padding: 3px 5px;
      border-radius: 10px;
      @include shadow;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .dailygk-listing {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(20vh);
    justify-content: space-evenly;
    margin-bottom: 60px;


    .dailygk-card {
      width: 30vw;
      margin-bottom: 20px;
      text-align-last: center;
      background-color: #fff;

      .dailygk-header {
        padding: 5px;
        letter-spacing: .5px;
        font-size: .7rem;
        background-color: $primary-color;
        color: $yellow-color;
        color: #fff;
      }
      .dailygk-body {
        letter-spacing: 1px;
        color: $primary-color;
        font-size: 1.2rem;
        font-weight: bold;
      }
      .dailygk-footer {
        letter-spacing: .5px;
        color: $primary-color;
        font-size: .8rem;
      }
    }
    .gk-listing-pagination {
      padding: 20px;

      .MuiPagination-ul {
        justify-content: center;
      }

    }
  }
}