@import "../../../styles/common.styles.scss";
#game-filters-title {
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: $gray-color;
  text-align: left;
  padding-bottom: 10px;
  position: relative;
  margin-top: 25px;
  .game-filters-subject {
    font-size: 1rem;
    letter-spacing: 1px;
    color: $red-color;
    text-align: left;
    padding-bottom: 10px;
    position: relative;
    margin-left: 5px;
    margin-top: 28px;
  }
}
.game-filters-dialog {
  min-width: 300px;
  min-height: 100px;
  max-width: 80vw;
  padding: 10px;

  .game-filters {

    .message-box-animation{
      display: flex;
      justify-content: center;
      .message-box-top-animation{
        width: 20vh;
      }
    }
    .filters-title {
      margin: 10px;
      border-bottom: 1px solid #ccc;
      letter-spacing: 1px;
      color:$gray-color;
    }

    .filters-list {
      display: flex;
      flex-wrap: wrap;

      .filter-item {
        background-color: $yellow-color;
        padding: 7px 13px;
        border-radius: 10px;
        margin: 5px;
        font-size: 0.9rem;
      }

      .filter-item:hover {
        cursor: pointer;
      }

      .selected-item {
        background-color: $purple-color;
        color: #fff;
      }
    }
  }

  .filter-button {
    margin-top: 20px;
    padding: 10px;
    text-align: right;

    button {
      margin-left: 20px;
    }
  }
}
