.questionBankContainer{
  .controlBox{
    margin-top: 20px;
    text-align: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    .formControl{
      min-width: 125px;
      margin: 10px;
    }
    .formButton{
      vertical-align: bottom;
    }
    .checkStyle{
      vertical-align: bottom;
    }
  }
} 
.newQuestionForm{
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #FFF;
  .formFields{
    margin-top: 10px;    
    display: inline-block;
    vertical-align: middle;
    .file-image{
      color:#004AAD;
      font-size: 3rem;
      vertical-align: middle;
    }
  }
  .halfWidth{
    width: 70%;
    margin-right: 10px;
    display: inline-flex;
  }
  img{
    width: 70px;
    margin-top: 10px;
    vertical-align: bottom;
  }
}

.questionListContainer{
  border: 1px solid #ddd;
  margin: 10px;
  .questionList{
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin-right: 30px;
    span{
      font-size: .8rem;
    }
  }
  .questionListIcon{
    color: #004AAD;
    .icon{
      margin: 5px;
    }
  }
  .questionListIcon:hover{
    cursor: pointer;
  }
}
.deleteIcon{
  color: rgb(173, 0, 0);
}

.buttonPanel{
  margin: 10px;
  align-items: center;
    width: 100%;
}
