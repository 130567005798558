@import "../../styles/common.styles.scss";

.redeem-page-container {
  padding-bottom: 70px;
  @include backgroundStyle;
  position: relative;

  .redeem-list-section {
    text-align: center;
    .redeem-top-section {
      .point-box {
        background-color: $blue-color;
        color: $yellow-color;
        font-size: 1.5rem;
        border-radius: 10px;
        display: inline-block;
        padding: 5px 7px;
        margin-top: 20px;
        margin-right: 20px;
        letter-spacing: 1px;
        margin-bottom: 20px;

        .redeem-code-tag {
          color: #fff;
          font-size: 0.8rem;
        }
      }
      .redeem-point-box {
        background-color: $green-color;
        color: $black-color;
      }

      .redeem-page-top {
        width: 100%;
        position: absolute;
        z-index: 0;
        top: -10px;

        img {
          width: 100%;
        }
      }

      .redeem-history {
        text-align: right;
        letter-spacing: 1px;
        z-index: 1;
        padding: 12px;
        top: 0;
        right: 0;
        z-index: 1;

        span {
          background-color: $yellow-color;
          color: $blue-color;
          border-radius: 20px;
          font-size: 1rem;
          padding: 3px 10px;
        }

        span:hover {
          cursor: pointer;
          background-color: $purple-color;
          color: #fff;
        }
      }

      .redeem-main-image {
        text-align: center;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;

        .redeem-page-top-animation {
          width: 25vw;
        }

        img {
          width: 50vw;
          max-width: 100%;
        }
      }

      .redeem-banner {
        display: flex;
        background-color: #fff;
        width: 94vw;
        min-height: 15vh;
        border-radius: 10px;
        margin-left: 3vw;
        @include shadow;
        position: relative;
        img{
            border-radius: 10px;
            max-width: 90vw;
            height: 100%;
        }
    }
      .redeem-page-title {
        margin: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;

        span {
          background-color: $blue-color;
          color: #fff;
          padding: 2px 10px;
          border-radius: 20px;
          font-size: smaller;
          float: right;
          cursor: pointer;
        }
      }
      
    }

    .redeem-gift-list {
      padding: 15px;
    }
  }

  .render-order-summary-card {
    @include shadow;
    padding: 20px;
    background-color: #fff;

    .order-summary-title {
      @include main-heading;
      text-align: center;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;
    }

    .order-gift-details {
      text-align: center;

      .gift-image {
        img {
          width: 50%;
        }
      }

      .gift-name {
        font-weight: bold;
        letter-spacing: 1px;
        color: $gray-color;
        padding: 0px 10px;
        margin-bottom: 10px;
      }

      .gift-points {
        span {
          background-color: $yellow-color;
          color: $purple-color;
          padding: 3px 7px;
          font-weight: bold;
          letter-spacing: 1px;
          border-radius: 5px;
        }
      }

      .gift-description {
        margin-top: 10px;
        font-size: 0.8rem;
        color: $gray-color;
        letter-spacing: 1px;
      }
    }

    .buttons-container {
      text-align: right;
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid #ccc;

      button {
        margin-left: 20px;
      }
    }
  }

  .order-status {
    text-align: center;

    .status-image {
      img {
        width: 300px;
      }
    }

    .status-title {
      @include main-heading;
    }

    .status-message {
      font-size: 1.2rem;
      color: $purple-color;
      letter-spacing: 1px;
    }
  }

  .redeem-note {
    margin-top: 10px;
    font-size: 0.8rem;
    color: $gray-color;
    letter-spacing: 1px;
    text-align: right;
  }
}
