@import '../../../styles/common.styles.scss';

.quiz-topic-list {
  @include page-container;

  .quizList {
    width: 85vw;
    margin: 0 auto;
  }

  .quizListHeader {
    @include main-heading;
    width: 85vw;
    margin: 0 auto;
    display: flex;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-top: 40px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }


  .showLoading {
    width: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
}