@import "../../styles/common.styles.scss";

.tab-header {
  width: 200px;
  .MuiTab-wrapper {
    display: inline-flex;
    align-items: left;
    flex-direction: inherit;
    justify-content: left;
  }
  .MuiTab-textColorInherit.Mui-selected {
    background-color: $purple-color;
    color: #fff;
  }
}
.admin-dashboard-main {
  min-height: calc(100vh - 100px);
  background-color: #efefff;
  overflow: auto;
  width: 100%;
  .dashboard-container-box {
    @include shadow;
    background-color: #fff;
    text-align: center;
    border-radius: 10px;
    animation: move-up 1s ease;
    padding-bottom: 10px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    text-align: left;
    .dashboard-card-title {
      text-align: left;
      letter-spacing: 1px;
      font-size: 1rem;
      color: $blue-color;
      margin-bottom: 10px;
      padding: 10px 20px;
      text-transform: uppercase;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 1px solid #ccc;
      font-weight: bold;
    }

    .dashboard-card-content {
      text-align: left;
    }
  }
}

.heading {
  flex-shrink: 0;
  font-weight: bold;
  letter-spacing: 1px;
  color: $purple-color;
  text-align: left;
}
