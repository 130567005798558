@import '../../../../styles/common.styles.scss';

.game-info-container {
  background-color: rgba(0, 0, 0, .9);
  position: absolute;
  width: 80vw;
  height: 80vh;
  top: 10vh;
  left: 10vw;
  border-radius: 3vw;
  border: 15px solid $yellow-color;
  color: #fff;

  .game-info-close-button {
    position: absolute;
    top: 1vw;
    right: 1vw;

    img {
      width: 3vw;
    }
  }

  .game-info-close-button:hover {
    cursor: pointer;
  }

  .game-info-image {
    margin-left: 30vw;
    margin-top: -5vw;
    width: 20vw;

    .game-info-image-animation{
      width: 20vw;
    }
  }


  .game-info-level-data {
    padding: 2vw;

    .game-levels-list {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      font-family: 'Patrick Hand', cursive;
      color: #000;
      font-size: 1.5rem;
      letter-spacing: 2px;
      display: flex;
      margin-left: 1vw;
      flex-wrap: wrap;
      justify-content: start;
      margin-top: -3vw;

      .game-level {
        margin: 10px 1vw;
        width: 5vw;
        height: 5vw;
        border-radius: 3vw;
        background-color: $blue-color;
        text-align: center;
        vertical-align: middle;
        line-height: 5vw;
        cursor: pointer;

        img {
          width: 3vw;
          line-height: 5vw;
          vertical-align: sub;
        }
      }

      .level-unlocked {
        background-color: $purple-color;
        animation: dance 2s ease;
        color: #fff;
      }

      .level-cleared {
        background-color: $green-color;
      }

      .level-cleared {
        color: greenyellow;
      }
    }
  }

  .game-exit-button {
    text-align: center;

    span {
      background-color: $yellow-color;
      color: #000;
      padding: 3px 10px;
      letter-spacing: 1px;
      border-radius: 5px;
      font-size: 1.2rem;


    }

    span:hover {
      cursor: pointer;
      background-color: $purple-color;
      color: $yellow-color;
    }

  }
}