@import "../../styles/common.styles.scss";

.lockedDialogContainer {
  text-align: center;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    width: 75%;
  }

  .loginMessage {
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 1rem;
    color: #fff;
  }

  .message-box-animation{
    display: flex;
    justify-content: center;
    .message-box-top-animation{
      width: 20vh;
    }
  }


  .loginButton {
    margin-top: 20px;

    .googleButton {
      img {
        width: 50%;
      }
    }

    .googleButton:hover {
      cursor: pointer;
    }
  }
}

.bg-horizontal {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  border: 2px solid $yellow-color;
  // background-image: url('https://www.cudents.com/prod/assets/images/homepage/horizontal_dialog.png');

}

.bg-vertical {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  border: 2px solid $yellow-color;
  // background-image: url('https://www.cudents.com/prod/assets/images/homepage/vertical_dialog.png');

}