@import "../../styles/common.styles.scss";

.portrait-to-landscape-animation {
    @include gradient;
    width: 40vw;
    border-radius: 40vw;
    left: auto;
    right: auto;
    margin: auto;
    margin-bottom: 40px;
  }