@import '../../styles/common.styles.scss';

.footerContainer {
  width: 100%;
  background-color: #333;
  min-height: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;

  .topArea {
    width: 80vw;
    margin: 0 auto;
    min-height: 100px;

    .footerHeader {
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 1px;
      color: $yellow-color;
    }

    .footerData {
      margin-right: 70px;
      letter-spacing: 1px;
      text-align: justify;
      margin-top: 20px;

      .footerLinks {
        margin-top: 10px;
      }

      .footerLinks:hover {
        cursor: pointer;
        color: #fdc453;
      }

      .contactInfo {
        vertical-align: super;
      }
    }

    .socialLinks {
      display: flex;
      margin-top: 20px;

      img {
        width: 40px;
        margin-right: 15px;
      }
    }

    .socialLinks img:hover {
      cursor: pointer;
    }

  }

  .bottomArea {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
    width: 80vw;
    margin-left: 10vw;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      margin-left: 10px;
      margin-right: 10px;
      color: #ffffff;
      text-decoration: none;
    }

    a:hover {
      cursor: pointer;
      color: #fdc453;
    }
  }

  .copyright {
    text-align: center;
  }
}