@import '../../../styles/common.styles.scss';

.point-system-page {
  @include page-container;
  padding: 10px;

  .main-image {
    text-align: center;

    img {
      width: 60vw;
    }

    .page-title {
      font-size: 1.1rem;
      color: $blue-color;
      text-transform: none;
    }
  }


  .points-info {
    margin-top: 50px;
    font-size: .8rem;

    .points-info-label {
      color: $purple-color;
      letter-spacing: 1px;
    }

    .points-info-details {
      color: #555;
      letter-spacing: 1px;

      ul {
        list-style-type: none;

        li::before {
          content: "- ";
        }
      }
    }
  }
}