@import '../../styles/common.styles.scss';

.learning-listing-page {
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 70px;
  position: relative;
  overflow-y: hidden;
  @include plainBackground;

  .learning-listing-page-top {
    width: 100vw;
    overflow: hidden;
    position: absolute;

    img {
      width: 100%;
    }
  }

  .top-animation {
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 2vh;

    .learning-page-top-animation {
      height: 25vh;
    }
  }

  .learning-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: -20px;
    margin-bottom: 60px;


    .learning-card {
      width: 45vw;
      margin-bottom: 7vw;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      @include shadow;
      position: relative;

      .learning-card-image {
        height: 23vw;
        overflow: hidden;

        img {
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .learning-title {
        height: 7vw;
        font-size: .9rem;
        letter-spacing: .5px;
        text-align: center;
        padding: 5px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-bottom: 10px;
      }
    }
  }
}