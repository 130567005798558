@import "../../styles/common.styles.scss";

.admin-video-details {
  @include page-container;
  padding: 20px;

  .videoFormContainer {
    @include shadow;
    background-color: #fff;
    min-height: 150px;
    text-align: center;
    border-radius: 10px;
    animation: move-up 1s ease;
    margin: 10px 5px;
    padding: 15px;
    border-top: 10px solid $purple-color;


    .videoForm {
      display: flex;
      width: 100%;

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .formField {
          min-width: 30%;
          text-align: left;
        }
      }
    }

    .videoTitle {
      text-align: left;
      letter-spacing: 1px;
      font-size: 1rem;
      color: $blue-color;
      margin-bottom: 10px;
      padding: 10px 20px;
      text-transform: uppercase;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 1px solid #ccc;
      font-weight: bold;
    }

    .sendButton {
      text-align: center;
      padding-top: 0px;

      Button {
        background-color: $purple-color;
        letter-spacing: 1px;
      }
    }

    .videoInfo {
      display: flex;
      justify-content: space-around;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      margin-top: 20px;
      color: $blue-color;

      span {
        vertical-align: top;
        color: $purple-color;
      }
    }
  }
}

.error-text {
  color: $red-color;
  font-size: small;
}

// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .videoContainer {
    .videoFormContainer {
      margin: 20px 0;
      margin-left: 20px
    }

    .videoInfo {
      flex-direction: column;
    }
  }
}