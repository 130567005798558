@import "../../styles/common.styles.scss";

.sales-list {
    @include shadow;
    margin: 20px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    .sales-title {
        @include main-heading;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
    .partner-sales {
        display: flex;
        padding-bottom: 10px;
        .userLabel {
            font-size: 1.2rem;
            color: $purple-color;
            font-weight: bold;
            letter-spacing: 1px;
        }

        .userValue {
            font-size: 1.2rem;
            color: #333;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
}
