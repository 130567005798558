@import '../../../styles/common.styles.scss';

.guess-master-player {
    // background-color: #d8f2ff;
    @include plainBackground;
    height: 100vh;
    overflow: auto;
    position: relative;

    .guess-master-background{
        position: absolute;
        top: 0;
        opacity: .5;
        img{
            min-height: 100vh;
        }
        z-index: -999;
    }

    .guess-master-background-animation{
        position: absolute;
        bottom: 0;
        z-index: -1;
    }

    .guess-master-top-area {
        display: flex;
        justify-content: center;

        .guess-master-top-animation {
            width: 50vw;
        }
    }

    .guess-master-question-area {
        display: flex;
        justify-content: center;
        .guess-master-question{
            width: 90vw;
            background-color: $primary-color;
            min-height: 20vh;
            border-radius: 20px;
            img{
                width: 100%;
            }
        }
    }

    .guess-master-answer-button{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        span{
            background-color: #fff;
            padding: 5px 10px;
            @include shadow;
            border: 1px solid $primary-color;
            border-radius: 10px;
            color: $primary-color;
            letter-spacing: 1px;
        }
    }

    .guess-master-answer-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
        width: 90vw;
        border-radius: 20px;
        align-items: center;
        background-color: $yellow-color;
        margin-right: 5vw;
        margin-left: 5vw;
        padding: 10px 0px;
        .guess-master-answer-text{
            font-size: 2rem;
            color: $purple-color;
            letter-spacing: 1px;
            text-transform: uppercase;
            animation: wiggle 1s ease;
        }
        .guess-master-answer-image{
            text-align: center;
            animation: wiggle 1s ease;
            img{
                width: 50%;
            }
        }
    }

    .guess-master-buttons{
        // display: flex;
        justify-content: space-evenly;
        .guess-master-previous-button{
            width: 50%;
            transform: scaleX(-1);
            float: left;
            text-align: left;
            .guess-master-button-animation{
                width: 30vw;
            }
        }
        .guess-master-next-button{
            width: 50%;
            float: right;
            text-align: right;
            .guess-master-button-animation{
                width: 30vw;
            }
        }
    }
}