@import '../../../../styles/common.styles.scss';

.contest-page-container {
  @include plainBackground;
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;
  padding-top: 60px;

  .button-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .cp-how-to-register {
      text-align: center;
      width: fit-content;
      letter-spacing: 1px;
      margin: 10px 5px;

      .video-player {
        .video-card {
          padding: 5px;
          margin: 0px;
          border-radius: 4px;
          background-color: $purple-color;
          color: #fff;

          .video-image {
            display: none !important;
          }

          .video-play-button {
            display: none;
          }

          .video-name {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 2px;
            padding-bottom: 5px;
            font-size: 0.875rem;
          }

        }

        .video-card:hover {
          cursor: pointer;
          animation: none !important;
          background-color: $yellow-color;
          color: $blue-color !important;
        }


      }
    }



  }

  .contest-page-section {
    width: 96vw;
    margin-left: 2vw;

    .contest-page-section-title {
      font-size: 1rem;
      letter-spacing: 1px;
      color: $blue-color;
      text-align: left;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
      position: relative;

      img {
        position: absolute;
        width: 75px;
        right: 20px;
        bottom: 0;
      }
    }

    .contest-page-listing {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}


// Media queries
@media only screen and (max-width: 767px) {

  .contest-page-container {
    .form-field {
      .request-button {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}