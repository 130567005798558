@import "../../styles/common.styles.scss";

.homeBannerContainer {
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    width: 100%;
  }

  .loginMessage {
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 1rem;
    color: #fff;
  }

  .message-box-animation {
    display: flex;
    justify-content: center;
    .message-box-top-animation {
      width: 20vh;
    }
  }
  .close-button {
    position: absolute;
    top: 1vw;
    right: 1vw;
    color: $yellow-color;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    font-size: 20px;
    font-weight: bolder;
    padding: 3px;
  }

  .loginButton {
    margin-top: 20px;

    .googleButton {
      img {
        width: 50%;
      }
    }

    .googleButton:hover {
      cursor: pointer;
    }
  }
}
