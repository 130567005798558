@import '../../../styles/common.styles.scss';

.contest-listing-page {
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 70px;
  position: relative;
  overflow-y: hidden;
  @include plainBackground;

  .contest-listing-page-top {
    width: 100vw;
    overflow: hidden;
    position: absolute;

    img {
      width: 100%;
    }
  }

  .top-animation {
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;

    .contest-page-top-animation {
      width: 70vw;
    }
  }

  .contest-listing {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-bottom: 60px;


    .contest-card {
      @include shadow;
      position: relative;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      width: 44vw;
      height: 44vw;
      overflow: hidden;
      margin-left: 4vw;
      border-radius: 10px;
      margin-top: 5vh;

      .card-title {
        position: absolute;
        top: 0;
        right: 0;
        background: $purple-color;
        color: #fff;
        padding: 0px 10px;
        font-size: .7rem;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        letter-spacing: 1px;
      }

      .contest-card-image {
        overflow: hidden;

        img {
          width: 100%;
        }
      }

      .contest-title {
        height: 7vw;
        font-size: .9rem;
        letter-spacing: .5px;
        text-align: center;
        padding: 5px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding-bottom: 10px;
      }
    }
  }
}