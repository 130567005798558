@import '../../../styles/common.styles.scss';

.quizzes-page {
  padding-bottom: 100px;
  min-height: 100vh;
  @include plainBackground;

  .subject-list {
    width: 100%;
    text-align: center !important;

    .grade-page-title {
      font-size: 1.2rem;
      background-color: $purple-color;
      color: #fff;
      letter-spacing: 1px;
      text-align: center;
      position: relative;
      padding: 20px 0px;



      img {
        position: absolute;
        height: 100px;
        right: 20px;
        bottom: 0;
      }


    }
  }
}