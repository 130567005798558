@import "../../styles/common.styles.scss";

.referral-list {
    margin: 20px;
    .refControlBox {
        margin-bottom: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .admin-ref-control {
            margin: 10px;
            vertical-align: bottom;
            .userLabel {
                font-size: 1.2rem;
                color: $purple-color;
                font-weight: bold;
                letter-spacing: 1px;
            }

            .userValue {
                font-size: 1.2rem;
                color: #333;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
    }
}
