@import '../../../../styles/common.styles.scss';

  .country-flag-box {
    background: linear-gradient(180deg,#001540 0,#570861 99%);
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 0;
    user-select: none;

    .country-flag-background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      opacity: .1;
      overflow: hidden;

      img {
        height: 100%;
      }

    }

    .game-timer-section {
      position: absolute;
      background-color: 0px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 5px;
      width: 5vw;
      display: inline-block;

    }

    .game-level-number {
      position: absolute;
      left: 5px;
      bottom: 5px;
      background-color: $purple-color;
      color: #fff;
      padding: 2px 5px;
      border-radius: 5px;
    }

    .game-question-section {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 20vw;
      font-size: 5rem;
      color: #fff;
      text-align: center;
      animation: move-down-mul 4s ease forwards;

      .game-question {
        background-color: rgba(0, 0, 0, .7);
        padding: 5px 20px;
        letter-spacing: 4px;
        border-radius: 10px;
      }
    }

    .answer-out {
      position: absolute;
      left: 0;
      right: 0;
      top: 20vh;
      margin: auto;
      z-index: 999;

      img {
        height: 50vh;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .bonus-message {
        background-color: rgba(255, 155, 0, .9);
        width: 60%;
        margin-left: 20%;
        color: black;
        padding: 5px;
        letter-spacing: 2px;
        font-size: 1.2rem;
        border-radius: 10px;
        font-weight: bold;
      }

      .game-button {
        background-color: $yellow-color;
        padding: 5px 10px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 10px;
        font-size: 1.5rem;
        letter-spacing: 1px;
        border: 3px solid #000;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .game-button:hover {
        cursor: pointer;
        background-color: $purple-color;
        color: #fff;
      }
    }


    .water-bubble-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      z-index: 2;



      .flag-option-1 {
        position: absolute;
        left: -21vh;
        top: 35vh;
        animation: left-right 23s ease;
        animation-timing-function: linear;
        animation-delay: 3s;

        img {
          border: 5px solid #fff;
          height: 13vh;
        }

        img:hover {
          border-color: $yellow-color;
          cursor: pointer;
        }
      }

      .flag-option-2 {
        position: absolute;
        right: -21vh;
        top: 20vh;
        animation: right-left 23s ease;
        animation-timing-function: linear;
        animation-delay: 4s;

        img {
          border: 5px solid #fff;
          height: 13vh;
        }

        img:hover {
          border-color: $yellow-color;
          cursor: pointer;
        }
      }

      .flag-option-3 {
        position: absolute;
        right: -21vh;
        top: 50vh;
        animation: right-left 23s ease;
        animation-timing-function: linear;
        animation-delay: 1s;
        z-index: -1;

        img {
          border: 5px solid #fff;
          height: 13vh;
        }

        img:hover {
          border-color: $yellow-color;
          cursor: pointer;
        }
      }

      .flag-option-4 {
        position: absolute;
        left: -21vh;
        top: 65vh;
        animation: left-right 23s ease;
        animation-timing-function: linear;
        animation-delay: 0s;

        img {
          border: 5px solid #fff;
          height: 13vh;
        }

        img:hover {
          border-color: $yellow-color;
          cursor: pointer;
        }
      }
    }

    .start-screen {
      width: 100%;
      height: 80%;
      margin-top: 5%;
      background-color: rgba(0, 0, 0, .8);
      text-align: center;
      letter-spacing: 1px;
      font-size: 2rem;
      color: #fff;

      .start-screen-message {
        font-family: 'Patrick Hand', cursive;
        padding: 10px;
      }

      img {
        width: 13vw;
        margin-top: 5vh;
      }

      button {
        font-size: 1.5rem;
        background-color: $yellow-color;
        border-radius: 20px;
        padding: 5px 10px;
        letter-spacing: 1px;
        margin-top: 30px;
      }

      button:hover {
        cursor: pointer;
        background-color: $red-color;
      }
    }

    .music-switch {
      margin-top: 20px;
      font-size: 1.2rem;
      letter-spacing: 1px;

      .MuiSwitch-root {
        .MuiSwitch-track {
          background-color: #fff;
        }
      }
    }

  }


  @keyframes move-down-mul {
    70% {
      bottom: 20vw;
      font-size: 5rem;
    }

    100% {
      bottom: 10px;
      font-size: 2rem;
    }
  }



  // Media queries
  @media only screen and (max-width: 1023px) {

    /* For mobile phones: */
    .country-flag-box {
      .start-screen {
        font-size: 1rem;
        width: 100vw;
        margin-left: 0;
        border-radius: 0px;

        button {
          margin-top: 10px;
          font-size: .8rem;
        }

        .music-switch {
          margin-top: 5px;
          font-size: 1rem;
        }
      }

      .answer-out {
        img {
          height: 50vh;
        }

        .game-button {
          font-size: 1rem;
        }
      }

      .water-bubble-container {
        .water-bubble {
          line-height: 7vw;

          img {
            width: 7vw;
          }

          .bubble-value {
            font-size: .8rem;
          }
        }
      }
    }
  }