.guidelineDialogContainer {
  min-height: 200px;
  text-align: center;
  padding: 20px;

  img {
    width: 100px;
  }

  .guidelineMessage {
    text-align: justify;
    letter-spacing: 1px;
    font-size: .9rem;
    color: #555;

    ol {
      li {
        margin-top: 5px;
      }
    }
  }

  .guidelineButton {
    margin-top: 20px;

    Button {
      margin: 10px;
    }
  }
}