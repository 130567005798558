@import '../../styles/common.styles.scss';

.rhymes-listing-page {
  min-height: 100vh;
  width: 100vw;
  @include plainBackground;
  position: relative;
  overflow-y: hidden;


  .rhymes-listing-page-top {
    width: 100vw;
    position: absolute;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .top-animation {
    text-align: center;

    .rhymes-page-top-animation {
      height: 20vh;
    }
  }

  .rhymes-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 60px;


    .rhyme-card {
      width: 45vw;
      height: 30vw;
      margin-bottom: 7vw;
      border-radius: 10px;
      position: relative;
      @include shadow;
      display: flex;
      background-color: #fff;
      flex-direction: column;

      .rhyme-card-image {
        height: 23vw;
        overflow: hidden;

        img {
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .rhyme-title {
        height: 7vw;
        font-size: .9rem;
        letter-spacing: .5px;
        text-align: center;
        padding: 5px 3px;
        color: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}