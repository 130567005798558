@import '../../../styles/common.styles.scss';

.quiz-subject-list {
  width: 100vw;

  .quiz-list {
    margin-top: 20px;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .quiz-list-card {
      width: 42vw;
      margin-bottom: 30px;
      position: relative;
    }
  }

  .quizList-header {
    @include main-heading;
    width: 90%;
    margin: 0 auto;
    display: flex;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 20px;
    border-bottom: 1px solid #ccc;

    .subject-name {
      font-size: 1.2rem;
      letter-spacing: 1px;
      color: $gray-color;
      text-align: left;
      padding-bottom: 10px;
      position: relative;
      margin-top: 25px;
    }

    .subject-details {
      font-size: 1rem;
      letter-spacing: 1px;
      color: $red-color;
      text-align: left;
      padding-bottom: 10px;
      position: relative;
      margin-left: 5px;
      margin-top: 28px;
    }
  }


  .showLoading {
    width: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
}