  
@import "../../styles/common.styles.scss";

  .support-main{
    @include shadow;
    margin: 20px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    .support-title {
      @include main-heading;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
  }
  .controlBox{
    margin: 10px;
    text-align: left;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .supportControl{
      min-width: 400px;
      margin: 10px;
    }
    .formButton{
      vertical-align: bottom;
    }
} 
