@import '../../styles/common.styles.scss';

.history-page {
  @include plainBackground;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 70px;

  .history-top-animation{
    display: flex;
    justify-content: center;
    width: 100vw;
    padding-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    .history-page-top-animation{
      width: 75vw;
    }
  }


  .top-section {
    display: flex;
    background-color: #fff;
    width: 94vw;
    min-height: 15vh;
    border-radius: 10px;
    margin-top: 5vh;
    margin-left: 3vw;
    margin-right: 3vw;
    @include shadow;
    position: relative;

    .top-animation{
      position: absolute;
      top: -7vw;
      right: -5vw;
      .top-section-animation{
        width: 17vw;
      }
    }

    .top-section-image {
      display: flex;
      align-items: center;

      img {
        width: 30vw;
      }
    }

    .top-section-info {
      width: 100%;
      padding: 0px 5px;
    }

    .top-section-info-title {
      padding: 10px 0px 3px 0px;
      font-weight: bold;
      font-size: .8rem;
      letter-spacing: 1px;
      color: $red-color;
    }

    .top-section-year{
      font-size: .6rem;
      font-weight: bold;
      color: #1bbcd2;
      letter-spacing: 1px;
    }

    .top-section-info-text {
      color: $primary-color;
      font-size: .7rem;
      letter-spacing: 1px;
    }

  }

  .history-pagination{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}