@import "../../styles/common.styles.scss";

.user-list {
    @include shadow;
    margin: 20px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    .user-title {
        @include main-heading;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
    .controlBox {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .ref-Control {
            margin: 10px;
            vertical-align: bottom;
            .userLabel {
                font-size: 1.2rem;
                color: $purple-color;
                font-weight: bold;
                letter-spacing: 1px;
            }

            .userValue {
                font-size: 1.2rem;
                color: #333;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
    }
}
