@import "../../styles/common.styles.scss";

.login-page {
  @include backgroundStyle;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  .login-container-responsive {
    min-height: 70vh;
    @include shadow;
    padding: 0px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;

    .login-page-animation {
      display: flex;
      justify-content: center;
      .login-page-top-animation{
        width: 70vw;
      }

    }

    .login-title-container {
      background-color: #fff;
      text-align: center;
      margin-bottom: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .login-page-title {
        color: $purple-color;
        font-size: 1.2rem;
        letter-spacing: 1px;
      }
    }

    .bottom-form {
      display: flex;
      padding: 5px 40px 20px 40px;
      text-align: center;
      flex-direction: column;
      justify-content: center;

      .or-separation {
        border-bottom: 1x solid red;
        color: $gray-color;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .login-title {
        font-size: 1.5em;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.65);
      }

      .formField {
        background-color: #fff;

        .PhoneInput {
          flex-direction: column !important;
          justify-content: center !important;

          .PhoneInputCountry {
            justify-content: center !important;
            margin-bottom: 15px !important;
            margin-top: 5px !important;
          }
        }
      }
    }
  }

  .tnc{
    margin-top: 5px;
    font-size: 0.8rem;
    color: $blue-color;
  }
  // welcome screen

  .basic-details-form {
    padding: 10px;

    .welcome-instructions {
      letter-spacing: 1px;
      color: #fff;
      margin-top: 30px;
    }

    .formContainer {
      margin-top: 10px;
      padding-top: 0px;
      text-align: center;

      .profile-success-buttons {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;

        .success-button {
          padding-top: 20px;
          margin: 10px 30px;

          span {
            display: inline-block;
            min-width: 150px;
            padding: 0px 10px;
            line-height: 40px;
            background: $yellow-color;
            text-align: center;
            position: relative;
            text-decoration: none;
            color: $blue-color;
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          span:before,
          span:after {
            position: absolute;
            content: "";
            transition: all 0.25s;
          }

          span:before {
            border-bottom: 2px solid $purple-color;
            border-left: 2px solid $purple-color;
            width: 10%;
            height: 33%;
            left: -10px;
            bottom: -10px;
          }

          span:after {
            border-top: 2px solid $purple-color;
            border-right: 2px solid $purple-color;
            width: 10%;
            height: 33%;
            top: -10px;
            right: -10px;
          }

          span:hover:before {
            width: 109%;
            height: 142%;
          }

          span:hover:after {
            width: 109%;
            height: 142%;
          }

          span:hover {
            cursor: pointer;
          }
        }

        .delay-animation {
          animation: wiggle 4s 4s infinite;

          span:hover:before {
            width: 108%;
            height: 140%;
          }

          span:hover:after {
            width: 108%;
            height: 140%;
          }
        }
      }

      img {
        width: 30%;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .profile-success {
      margin-top: 50px;
    }
  }

  // end welcome page

  .link-text {
    cursor: pointer;
    text-decoration: underline;
    color: $blue-color;
  }

  .contest-button {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .create-button {
    padding: 7px 10px;
    border-radius: 50px;
    color: $blue-color;
    letter-spacing: 1px;
    font-size: 0.8rem;
    font-weight: bold;
    border: 1px solid $blue-color !important;
    @include shadow;
  }

  .create-button:hover {
    cursor: pointer;
    background-color: $purple-color;
    color: #fff;
  }

  .mobileMenuItem{
    margin-top: 10px;
  }

  // Google button css
  #customBtn {
    display: inline-block;
    background: #fff;
    padding: 4px 10px;
    border-radius: 50px;
    // box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    margin: 5px 0;
  }

  #customBtn:hover {
    cursor: pointer;
  }

  .login-submit-button {
    background-color: $purple-color !important;
    color: #fff !important;
    padding: 5px 30px !important;
    margin-top: 30px !important;
  }

  span.label {
    font-family: serif;
    font-weight: normal;
  }

  span.icon {
    // background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    margin-right: 10px;

    img {
      width: 80%;
      vertical-align: middle;
    }
  }

  span.buttonText {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    letter-spacing: 2px;
    // / Use the Roboto font that is loaded in the <head> /
    // font-family: 'Roboto', sans-serif;
  }
}

.formField {
  margin: 20px 0px;

  .cp-dropdown {
    text-align: left;
  }

  Button {
    vertical-align: bottom;
  }

  .error-msg {
    color: $red-color;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: left;
  }

  .text-msg {
    color: $gray-color;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: left;
  }
}

// Will refactor later
// for change password in dashboard
#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  padding: 4px 10px;
  border-radius: 50px;
  border: thin solid #ccc;
  // box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
  margin-top: 20px;
}

#customBtn:hover {
  cursor: pointer;
}

span.label {
  font-family: serif;
  font-weight: normal;
}

span.icon {
  // background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  margin-right: 10px;

  img {
    width: 80%;
    vertical-align: middle;
  }
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  letter-spacing: 0.5px;
  // / Use the Roboto font that is loaded in the <head> /
  // font-family: 'Roboto', sans-serif;
}

.error-msg {
  color: $red-color;
  font-size: 0.8rem;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.change-password {
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  padding: 10px;

  .login-title {
    margin-bottom: 20px;
    letter-spacing: 1px;
    text-align: center;
  }

  form {
    text-align: center;
  }
}

.otp-timer {
  display: flex;
  justify-content: center;
  width: 100%;
}


// Media queries
@media only screen and (max-width: 767px) {
  .login-page {
    .basic-details-form {
      .formContainer {
        img {
          width: 80%;
        }
      }
    }
  }
}