@import "../../styles/common.styles.scss";

.toys-video-player-page,
.learning-video-player-page,
.news-video-player-page,
.rhyme-video-player-page,
.story-video-player-page,
.cudents-video-player-page {
  @include plainBackground;
  display: flex;
  flex-direction: column;
  position: relative;

  .video-player-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .video-description-container {
    padding-top: 40px;
    letter-spacing: 1px;
    .video-description-content {
      textarea {
        color: $black-color;
      }
    }
    .video-description-title {
      color: $primary-color;
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: 1px;
    }
  }

  .video-details-container {
    display: flex;
    background-color: $green-color;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0px;

    .video-like-container {
      text-align: center;
      color: #fff;
    }
  }

  .video-description-container {
    padding: 10px;
  }
}

.learning-video-player-page {
  .video-details-container {
    background-color: $blue-color;
  }
}

.toys-video-player-page {
  .video-details-container {
    background-color: $purple-color;
  }
}

.news-video-player-page {
  .video-details-container {
    background-color: $yellow-color;
  }
}

.story-video-player-page {
  .video-details-container {
    background-color: $gray-color;
  }
}

@media screen and (min-width: 320px) and (max-width: 1200px) and (orientation: landscape) {
  .toys-video-player-page,
  .learning-video-player-page,
  .news-video-player-page,
  .rhyme-video-player-page,
  .story-video-player-page,
  .cudents-video-player-page {
    width: 100vw;
    height: 100vh;
    padding-top: 0px;

    .video-player-container {
      width: 100vw;
      height: 100vh;
    }

    .video-details-container {
      display: none;
    }

    .video-description-container {
      display: none;
    }
  }
}

#cd-video-player-drawer {
  .MuiDrawer-paper {
    width: 70vw;
  }
}
