@import '../../styles/common.styles.scss';

.infoArea {
  margin: 50px 50px 20px 50px;
  animation: move-up 1s ease;

  .infoIcon {
    img {
      width: 50px;
      margin-bottom: 10px
    }
  }

  .infoTitle {
    @include main-heading;
    text-transform: none;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color:$purple-color;
  }

  .infoData {
    font-size: .9rem;
    letter-spacing: 1px;
    color:$gray-color;
  }
}