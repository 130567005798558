@import '../../../styles/common.styles.scss';

.guess-master-list-page {
    @include plainBackground;
    min-height: 100vh;

    .guess-master-main-top-image{
        position:absolute;
        top: 0;
        img{
            width: 100%;
        }
    }

    .guess-master-main-top-section {
        display: flex;
        justify-content: center;
        padding-top: 10vw;
        padding-bottom: 10vw;

        .guess-master-main-animation {
            width: 30vw;
        }
    }

    .guess-master-main-card-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .guess-master-main-card {
            border-radius: 10px;
            width: 42vw;
            background-color: #fff;
            text-align: center;
            @include shadow;
            margin-bottom: 30px;
            img {
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
}