@import '../../styles/common.styles.scss';

.assessment-page {
  @include page-container;
  @include gradient;
  min-height: 100vh;
  padding-top: 60px;
  user-select: none;

  .top-right-shape {
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 40vw;
    }
  }

  .bottom-left-shape {
    img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40vw;
    }
  }

  .assessment-result-container {
    @include shadow;
    background-color: #fff;
    min-height: calc(100vh - 250px);
    border-radius: 10px;
    padding: 20px;
    margin-top: -20px;
    z-index: 0;
    position: relative;
    text-align: center;

    .assessment-done-image {
      .result-title {
        text-align: center;
        letter-spacing: 1px;
        font-size: 1.2rem;
        color: #555;
        margin-bottom: 20px;
        padding: 10px 20px;
      }
    }

    .assessment-result-title {
      @include main-heading;
      text-align: left;
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;

      .result-label {
        text-align: left;
        font-size: 1.2rem;
        text-transform: none;
      }
    }

    .confirmation-buttons {
      display: flex;
      justify-content: center;

      .dashboard-button {
        margin-left: 20px;

        span {
          margin-left: 20px;
        }
      }
    }

    .sub-title {
      text-align: left;
      letter-spacing: 1px;
      font-size: 1.1rem;
      color: $blue-color;
      margin-bottom: 10px;
      padding: 10px 20px;
      text-transform: uppercase;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
    }

    .result-percentage {
      text-align: center;
      color: $blue-color;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 1.2rem;
      margin-top: -10px;
    }

    .result-label {
      text-align: center;
      color: $purple-color;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }

    .result-info-section {
      margin-top: 20px;

      .result-section-details {
        margin-top: 10px;

        .strong-area {
          background-color: #5be12c;
          color: #333;
          letter-spacing: 1px;
          padding: 2px 13px;
          border-radius: 40px;
          margin-right: 10px;
        }

        .area-improvement {
          background-color: #ea4228;
          color: #fff;
          letter-spacing: 1px;
          padding: 2px 13px;
          border-radius: 40px;
          margin-right: 10px;
        }
      }

      .result-section-label {
        color: $blue-color;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }

    .assessment-result-image {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;

      img {
        opacity: .2;
        width: 70%;
        float: right;
      }
    }

    .subject-section {
      margin: 30px 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      min-height: 175px;

      .section-title {
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        color: $purple-color;
        font-weight: bold;
        letter-spacing: 1px;
        align-items: center;

        img {
          width: 75px;
        }
      }

      .subject-list {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;

        span {
          margin: 0px 5px 10px 5px;
          border: 1px solid $purple-color;
          color: $blue-color;
          background-color: $yellow-color;
          border-radius: 20px;
          padding: 2px 7px;
          letter-spacing: 1px;
          font-size: .9rem;
        }

        div {
          font-size: .8rem;
          color: #555;
          letter-spacing: 1px;
        }
      }
    }

    .dashboard-button {
      text-align: center;
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      span {
        @include shadow;
        padding: 5px 10px;
        background-color: $blue-color;
        color: #fff;
        border-radius: 30px;
        letter-spacing: 1px;
        margin-left: 20px;
        margin-top: 10px;
      }

      span:hover {
        cursor: pointer;
        color: $yellow-color;
      }
    }

  }
}