@import '../../styles/common.styles.scss';

.riddle-page {
  height: 100vh;
  padding-bottom: 70px;
  @include plainBackground;
  position: relative;

  .riddle-number {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: $primary-color;
    color: #fff;
    padding: 10px;
    border-radius: 100%;
    .riddle-audio {
      position: absolute;
      top: -3px;
      left: 0;
      right: 0;
      margin: auto;

      span {
        button {
          background-color: #fff !important;
          width: 30px !important;
          height: 30px !important;
          border-radius: 50px !important;
          color: #fff !important;
          font-size: 2rem;
          padding-bottom: 10px !important;
          cursor: pointer;
        }
      }
    }
  }

  .riddle-top-animation {
    padding-top: 5vh;
    height: 20vh;
    background-color: #fff;

    .riddle-page-top-animation {
      height: 20vh;
    }
  }
  .riddle-top-section {
    background-color: #fff;
    min-height: 10vh;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

  .riddle-question {
    padding: 10px 10px;
    text-align: center;
    letter-spacing: 1px;
    color: rgb(24, 34, 126);
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .riddle-image {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;

    .riddle-question-animation {
      width: 50vw;
    }
  }
  .riddle-title{
    padding-top: 20px;
    color: rgb(139, 64, 64);
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
  }
  .riddle-answer {
    padding-top: 10px;
    margin-top: -3px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    color:$green-color;
  }
}

.blank-shadow{
  @include shadow;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.no-riddle{
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  letter-spacing: 1px;
}

.previous-riddle{
  background-color: transparent !important;
}


  .riddle-history-button {
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      background-color: $yellow-color;
      color: $purple-color;
      padding: 3px 5px;
      border-radius: 3px;
    }

    span:hover {
      cursor: pointer;
    }
  }

}