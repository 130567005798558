@import '../../styles/common.styles.scss';

.user-dashboard-container {
  @include plainBackground;
  min-height: 90vh;
  padding-bottom: 70px;

  .floating-dashboard-button {
    position: fixed;
    right: 5px;
    top: 5px;
    margin: auto;
    z-index: 999;
    // background-color: #fff;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 3px;

    img {
      width: 100%;
    }

  }

  .dashboard-top-user-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    .dashboard-top-user-section-image {
      img {
        width: 25vw;
      }
    }

    .dashboard-top-user-section-info {
      letter-spacing: 1px;
      text-align: center;

      .dashboard-top-user-section-wish {
        font-weight: bold;
        color: #333;
      }

      .dashboard-top-user-section-username {
        color: $red-color;
        font-weight: bold;
        margin-top: -3px;
      }

      .dashboard-top-user-section-usergrade {
        color: $gray-color;
        font-size: .8rem;
        margin-top: -3px;
      }
    }
  }

}