.yesNoDialogContainer {
  text-align: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20px;
  img {
    width: 75%;
  }

  .message-box-animation{
    display: flex;
    justify-content: center;
    .message-box-top-animation{
      width: 20vh;
    }
  }


  .message {
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 1rem;
    color: #fff;
  }

  .buttonPanel {
    margin-top: 10px;
    justify-content: space-between;

    Button {
      margin: 10px;
    }
  }
}

// .bg-horizontal {
//   min-height: 200px;
//   min-width: 300px;
//   background-image: url('https://www.cudents.com/prod/assets/images/homepage/horizontal_dialog.png');

// }

// .bg-vertical {
//   width: 80vw;
//   background-color: red;
//   background-image: url('https://www.cudents.com/prod/assets/images/homepage/vertical_dialog.png');

// }