@import "../../styles/common.styles.scss";

.group-listing-page {
  min-height: 100vh;
  width: 100vw;
  @include backgroundStyle;
  position: relative;
  overflow-y: hidden;

  .group-listing-page-top {
    width: 100vw;
    position: absolute;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .formField {
    display: flex;
    margin: 10px;
  }

  .top-animation {
    text-align: center;
    width: 100vw;
    img {
      width: 100%;
    }
  }

  .group-title {
    height: 7vw;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 5px 3px;
    color: $blue-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .my-group {
    font-size: 1rem;
    font-weight: bold;
    color: $primary-color;
  }

  .group-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;

    .group-card {
      background-color: #fff;
      margin: 20px 10px;
      border-radius: 10px;
      width: 38vw;
      @include shadow;

      img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .group-card-name {
        font-size: 1rem;
        color: $purple-color;
        letter-spacing: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .group-card-tag {
        font-size: 0.8rem;
        color: $gray-color;
        letter-spacing: 1px;
        padding-bottom: 5px;
      }
      .group-card-image{
        position: relative;
        .group-card-edit {
          position: absolute;
          top: 1vw;
          right: 1vw;
          background: rgba(225, 168, 248, 0.8);
          border-radius: 10vw;
          width: 6vw;
          height: 6vw;
          padding: 1vw;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
