@import '../../styles/common.styles.scss';
.new-group-container{
    min-height: 100vh;
    padding: 20px;
    text-align: center;

    .group-title {
      height: 7vw;
      font-size: 1.2rem;
      letter-spacing: 0.5px;
      text-align: center;
      padding: 5px 3px;
      color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .group-image {
        img {
          width: 50%;
        }

        .editGroupImage {
          font-weight: bold;
          font-size: 0.9rem;
          color: $blue-color;
          letter-spacing: 1px;
          background-color: $yellow-color;
          margin: 0px 10px;
          border-radius: 30px;
        }

        .editGroupImage:hover {
          cursor: pointer;
          background-color: $purple-color;
          color: #fff;
        }
      }
      .formField {
        margin-bottom: 15px;
        .saveButton {
          text-align: center;
          margin-left: 10px;
        }
      }

      .error-text {
        color: $red-color;
        font-size: small;
      }
}