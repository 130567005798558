@import '../../../styles/common.styles.scss';

.app-home-page {
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #ffe3eb;
  position: relative;
  z-index: 0;
  padding-bottom: 15vw;
  @include backgroundStyle;

  .custom-dot-list-style{
    position:relative;
  }

  .music-icon {
    position: absolute;
    top: 5px;
    right: 1vw;
    z-index: 1;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
    }
  }

  .music-icon:hover {
    cursor: pointer;
  }

  .home-page-top {
    width: 100%;
    position: absolute;
    z-index: -1;

    img {
      width: 100%;
    }

    .home-top-animation {
      position: absolute;
      top: -13px;
      right: 25vw;
      animation: home-page-swing 3.5s ease-in-out forwards infinite;

      img {
        width: 30vw;
      }
    }
  }

  .middle-animation {
    .home-page-middle-animation {
      position: fixed;
      top: 25vh;
      // left: -100vw;
      width: 100vw;
      // animation: left-right-up 20s ease infinite;
      z-index: 9;
    }
  }

  .home-page-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .2;

    img {
      height: 100vh;
      width: 100vw;
    }
  }

  .home-top-section {
    display: flex;
    background-color: #fff;
    width: 94vw;
    min-height: 15vh;
    border-radius: 10px;
    margin-top: 5vh;
    margin-left: 3vw;
    @include shadow;
    position: relative;

    .home-top-animation{
      position: absolute;
      top: -7vw;
      right: -5vw;
      .home-top-section-animation{
        width: 17vw;
      }
    }

    .home-top-section-image {
      display: flex;
      align-items: center;

      img {
        width: 30vw;
      }
    }

    .home-top-section-info {
      width: 100%;
      padding: 0px 5px;
    }

    .home-top-section-info-title {
      padding: 10px 0px 3px 0px;
      font-weight: bold;
      font-size: .8rem;
      letter-spacing: 1px;
      color: $red-color;
    }

    .home-top-section-year{
      font-size: .6rem;
      font-weight: bold;
      color: #1bbcd2;
      letter-spacing: 1px;
    }

    .home-top-section-info-text {
      color: $primary-color;
      font-size: .7rem;
      letter-spacing: 1px;
    }

  }

  .home-sections-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 7vw;

    .home-section-card {
      width: 28vw;
      background-color: rgba(0, 0, 0, .7);
      margin-bottom: 5vh;
      margin-left: 4vw;
      box-shadow: 1px 2px 10px #333;
      border-radius: 2vw;

      .home-section-card-image {
        width: 100%;

        img {
          width: 100%;
          border-top-left-radius: 2vw;
          border-top-right-radius: 2vw;
        }
      }

      .home-section-card-title {
        text-align: center;
        font-family: 'Kalam', cursive;
        color: yellow;
        font-size: .8rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    .group-image{
      width: 30vw;
    }
    //testing new design
    .home-section-card-new-design {
      width: 45vw;
      height: 20vw;
      margin-bottom: 5vw;
      margin-top: 8vw;
      border-radius: 2vw;
      display: flex;
      justify-content: space-evenly;
      padding: 1vw;

      .home-section-card-image-new-design {
        display: flex;
        align-items: end;

        img {
          height: 30vw;
        }
      }

      .home-section-card-title-new-design {
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        color: #fff;
        font-size: 1rem;
        letter-spacing: 1px;
        margin-right: 10px;
      }
    }

    .card-full-width {
      width: 94vw;
      height: 25vw;
      padding: 1vw;
      margin-top: 10vw;
      align-items: end;
      justify-content: space-evenly;
      position: relative;

      .home-section-card-image-new-design {
        img {
          height: 35vw;
          z-index: 1;
        }
      }

      .home-section-card-title-new-design {
        font-size: 1.3rem;

      }

      .star-animation {
        position: absolute;
        z-index: 0;
        width: 50vw;
        left: 2vw;
      }

      .angel-animation {
        position: absolute;
        z-index: 0;
        width: 50vw;
        right: -20vw;
        top: -10vw;
      }

      .trophy-animation {
        position: absolute;
        z-index: 0;
        width: 20vw;
        right: -5vw;
        top: -10vw;
      }

      .camera-animation {
        position: absolute;
        z-index: 0;
        width: 20vw;
        left: -5vw;
        top: -10vw;
      }
      .question-animation {
        position: absolute;
        z-index: 0;
        width: 20vw;
        left: -2vw;
        top: -10vw;
      }
    }

    .card-blue {
      background-color: #1bbcd2;
    }

    .card-yellow {
      background-color: #ffc51b;
    }

    .card-purple {
      background-color: #a15d98;
    }

    .card-pastel-purple{
      background-color: #4fbcd3;;
    }

    .card-pink {
      background-color: #fb4981;
    }

    .card-green {
      background-color: #48b86a;
    }

    .card-brown {
      background-color: #c16358;
    }

    .card-red {
      background-color: #ea5f3c;
    }

    .card-teal {
      background-color: #01a9a7;
    }

    .card-grey {
      background-color: #8289af;
    }

  }


  @keyframes home-page-right-left {
    0% {
      right: -50vw;
    }

    100% {
      right: 200vw;
    }
  }

  @keyframes left-right-up {
    0% {
      left: -100vw;
      bottom: 25vh;
    }

    100% {
      left: 300vw;
      bottom: 130vh;
    }
  }


  @keyframes home-page-swing {
    0% {
      transform: rotate(10deg);
    }

    50% {
      transform: rotate(-10deg)
    }

    100% {
      transform: rotate(10deg);
    }
  }


}



.game-class-selection-box {
  background-color: $purple-color;
  color: #fff;
  min-width: 90vw;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden !important;
  font-family: 'Kalam', cursive;


  .game-class-selection-button {
    background-color: #fff;
    width: 60%;
    text-transform: uppercase;
    color: $purple-color;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 1.2rem;
    height: 5vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      height: 10vh;
    }
  }
}