@import '../../../../styles/common.styles.scss';

.level-card-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  // box-shadow: 0px 9px 10px rgba(86,124,173,.25);
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  text-align: center;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  .levelTitle {
    letter-spacing: 1px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: $purple-color;
  }

  img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  p {
    letter-spacing: 1px;
    margin-top: 5px;
    margin-bottom: 10px;
    color:$gray-color;
    font-weight: bold;
  }
}

.level-card-container:hover {
  transform: scale(1.05);
}