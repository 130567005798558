@import '../../styles/common.styles.scss';

.quiz-page-common {
  @include gradient;
  min-height: 100vh;
  user-select: none;

  .top-left-shape {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 40vw;
    }
  }

  .bottom-right-shape {
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40vw;
    }
  }
}