@import '../../../../styles/common.styles.scss';

  .mul-game-box {
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 0;
    user-select: none;

    .mul-game-background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }

    }

    .game-timer-section {
      position: absolute;
      background-color: 0px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 5px;
      width: 5vw;
      display: inline-block;

    }

    .game-level-number {
      position: absolute;
      left: 5px;
      bottom: 5px;
      background-color: $purple-color;
      color: #fff;
      padding: 2px 5px;
      border-radius: 5px;
    }

    .game-question-section {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 20vw;
      font-size: 5rem;
      color: #fff;
      text-align: center;
      animation: move-down-mul 4s ease forwards;

      .game-question {
        background-color: rgba(0, 0, 0, .7);
        padding: 5px 20px;
        letter-spacing: 4px;
        border-radius: 10px;
      }
    }

    .answer-out {
      position: absolute;
      top: 10vh;
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      img {
        height: 25vw;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .bonus-message {
        background-color: rgba(255, 155, 0, .9);
        width: 60%;
        text-align: center;
        color: black;
        padding: 5px;
        letter-spacing: 2px;
        font-size: 1.2rem;
        border-radius: 10px;
        font-weight: bold;
      }

      .game-button {
        background-color: $yellow-color;
        padding: 5px 10px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 10px;
        font-size: 1.5rem;
        letter-spacing: 1px;
        border: 3px solid #000;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .game-button:hover {
        cursor: pointer;
        background-color: $purple-color;
        color: #fff;
      }
    }


    .water-bubble-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      z-index: 2;



      .game-fish-1 {
        position: absolute;
        left: -10vw;
        top: 15vw;
        animation: left-right 15s ease;
        animation-timing-function: linear;
        animation-delay: 3s;

        img {
          width: 7vw;
        }
      }

      .game-fish-2 {
        position: absolute;
        right: -10vw;
        top: 7vw;
        animation: right-left 15s ease;
        animation-timing-function: linear;
        animation-delay: 2s;

        img {
          width: 7vw;
        }
      }

      .game-fish-3 {
        position: absolute;
        right: -10vw;
        top: 25vw;
        animation: right-left 15s ease;
        animation-timing-function: linear;
        animation-delay: 1s;
        z-index: -1;

        img {
          width: 7vw;
        }
      }

      .shark-fish-1 {
        position: absolute;
        left: -14vw;
        top: 25vw;
        animation: left-right 10s ease infinite;
        animation-timing-function: linear;
        animation-delay: .5s;

        img {
          width: 13vw;
        }
      }

      .shark-fish-2 {
        position: absolute;
        right: -14vw;
        top: 7vw;
        animation: right-left 10s ease infinite;
        animation-timing-function: linear;
        animation-delay: 1s;

        img {
          width: 13vw;
        }
      }

      .water-bubble {
        position: relative;
        animation: bottom-top 17s ease forwards;
        animation-timing-function: linear;
        z-index: 100;
        left: 10px;
        margin-top: 100vh;
        opacity: 1;
        color: #000;
        line-height: 5vw;
        vertical-align: middle;

        img {
          width: 5vw;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          border: none !important;
          z-index: -1;
        }

        img:hover {
          cursor: pointer;
        }

        .bubble-value {
          font-size: 1.2rem;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;
          z-index: 9;
        }

        .bubble-value:hover {
          cursor: pointer;
        }
      }

      .water-bubble:hover {
        cursor: pointer;
        opacity: 1;
        color: $purple-color;
      }

      .bubble-delay-1 {
        animation-delay: 2.5s;
      }

      .bubble-delay-2 {
        animation-delay: 3s;
      }

      .bubble-delay-3 {
        animation-delay: 3.5s;
      }

      .bubble-delay-4 {
        animation-delay: 4s;
      }

      .bubble-delay-5 {
        animation-delay: 4.5s;
      }

      .bubble-delay-6 {
        animation-delay: 5s;
      }

      .random-bubble {
        animation: bottom-top-fade 17s ease forwards;
        animation-timing-function: linear;
        text-align: left;
        margin-left: 15vw;
      }

      .random-bubble-1 {
        animation: bottom-top-fade 17s ease forwards;
        animation-timing-function: linear;
        animation-delay: 3s;
        text-align: left;
        margin-left: -35vw;
      }
    }

    .start-screen {
      width: 100%;
      height: 80%;
      margin-top: 5%;
      background-color: rgba(0, 0, 0, .8);
      text-align: center;
      letter-spacing: 1px;
      font-size: 2rem;
      color: #fff;

      .start-screen-message {
        font-family: 'Patrick Hand', cursive;
        padding: 10px;
      }

      img {
        width: 10vw;
      }

      button {
        font-size: 1.5rem;
        background-color: $yellow-color;
        border-radius: 20px;
        padding: 5px 10px;
        letter-spacing: 1px;
        margin-top: 30px;
      }

      button:hover {
        cursor: pointer;
        background-color: $red-color;
      }
    }

    .music-switch {
      margin-top: 20px;
      font-size: 1.2rem;
      letter-spacing: 1px;

      .MuiSwitch-root {
        .MuiSwitch-track {
          background-color: #fff;
        }
      }
    }

  }


  @keyframes move-down-mul {
    70% {
      bottom: 20vw;
      font-size: 5rem;
    }

    100% {
      bottom: 10px;
      font-size: 2rem;
    }
  }



  // Media queries
  @media only screen and (max-width: 1023px) {

    /* For mobile phones: */
    .mul-game-box {
      .start-screen {
        font-size: 1rem;
        width: 100vw;
        margin-left: 0;
        border-radius: 0px;

        button {
          margin-top: 10px;
          font-size: .8rem;
        }

        .music-switch {
          margin-top: 5px;
          font-size: 1rem;
        }
      }

      .answer-out {
        img {
          height: 25vw;
        }

        .game-button {
          font-size: 1rem;
        }
      }

      .water-bubble-container {
        .water-bubble {
          line-height: 7vw;

          img {
            width: 7vw;
          }

          .bubble-value {
            font-size: .8rem;
          }
        }
      }
    }
  }