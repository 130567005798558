@import '../../../styles/common.styles.scss';

.games-user-dashboard-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 100vh;
  padding-bottom: 70px;
  @include plainBackground;

  .game-listing-page-top {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      position: absolute;
      top: 0;
    }
    .game-listing-top-animation {
      .game-listing-animation{
        margin-top: 3vh;
        width: 70vw;
      }
    }
  }

  .game-card {
    background-color: #fff;
    border-radius: 10px;
    margin: 15px 10px 20px 10px;
    width: 43vw;
    text-align: center;
    @include shadow;
    position: relative;


    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .game-name {
      color: $purple-color;
      letter-spacing: 1px;
      padding: 0px 3px;
      font-size: .8rem;
    }

    .game-tag {
      font-size: .6rem;
      color: $gray-color;
      letter-spacing: 1px;
      padding-bottom: 10px;
      padding-left: 3px;
      padding-right: 3px;
    }

  }

  .game-card:hover {
    cursor: pointer;
    animation: box-move-up .5s ease forwards;
  }
  .game-paging{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}

// Media queries
@media only screen and (max-width: 1023px) {
  .games-user-dashboard-listing {
    .game-card {

      .game-name {
        font-size: .9rem;
      }

      .game-tag {
        font-size: .7rem;
      }
    }
  }
}