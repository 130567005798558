@import '../../../styles/common.styles.scss';

.search-master-game-container {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: #fcf;
  overflow: hidden;

  .search-master-game-loader {
    height: 100%;
    width: 100%;
    background-color: yellowgreen;
  }

  .search-master-game-start-screen {
    height: 100%;
    background-color: yellowgreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Patrick Hand', cursive;

    .search-master-game-title {
      font-weight: bolder;
      font-size: 3rem;
      color: yellow;
      letter-spacing: 3px;
      text-shadow: 2px 2px #000;
      text-transform: uppercase;

    }

    .search-master-game-start-image {
      width: 20vw;
    }

    .search-master-game-start-play-button {
      display: inline-block;
      padding: 1vh 4vh;
      letter-spacing: 4px;
      border-radius: 5vh;
      font-size: 2rem;
      font-weight: bold;
      background-color: green;
      color: yellow;
      box-shadow: 1px 2px 5px #555;
      border: 3px solid yellow;
    }

    .search-master-game-start-play-button:hover {
      cursor: pointer;
    }

    .search-master-game-start-exit-button {
      display: inline-block;
      padding: 1vh 4vh;
      letter-spacing: 4px;
      border-radius: 5vh;
      margin-left: 2vw;
      font-size: 2rem;
      font-weight: bold;
      background-color: red;
      color: yellow;
      box-shadow: 1px 2px 5px #555;
      border: 3px solid yellow;
    }

    .search-master-game-start-exit-button:hover {
      cursor: pointer;
    }

    .search-master-game-start-music-button {
      width: 5vw;
      margin-top: 2vh;

      img {
        width: 100%;
      }

      img:hover {
        cursor: pointer;
      }
    }

  }

  .search-master-game-play-area {
    height: 100vh;
    width: 100vw;
    display: flex;

    .search-master-game-bg {
      height: 100vh;
      width: 75vw;
      overflow: hidden;

      img {
        width: 100%;
        height: 100vh;
      }
    }
    
    .sequence-game-level-button {
      position: absolute;
      top: 1vh;
      left: 1vh;
      width: 7vw;
      color: #fff;
      background-color: $purple-color;
      border-radius: 5vw;
      padding: 2px 12px;
    }

    .search-master-game-show-question {
      display: flex;
      flex-direction: column;
      position: absolute;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, .5);
      border-radius: 100%;
      justify-content: center;
      animation: search-master-game-question linear 5s forwards;

      .search-master-game-show-question-label {
        text-transform: uppercase;
        font-weight: bold;
        font-family: 'Patrick Hand', cursive;
        font-size: 1.5rem;
        text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
          1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
      }
      
      .search-master-game-show-msg {
        font-weight: bold;
        font-family: 'Patrick Hand', cursive;
        text-align: center;
        color: $purple-color;
        letter-spacing: 1px;
        font-size: 1rem;
        text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
          1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
      }

      .search-master-game-show-question-image {
        img {
          width: 10vw;
        }
      }

      @keyframes search-master-game-question {
        0% {
          transform: scale(0);
        }

        50% {
          transform: scale(2);
        }

        100% {
          transform: scale(0);
        }
      }

    }

    .search-master-game-options-area {
      width: 25vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .search-master-game-options-question {
        text-align: center;
        font-size: 1.1rem;
        color: $purple-color;
        letter-spacing: 1px;
        font-weight: bold;
        text-transform: uppercase;

        .search-master-game-options-question-image {

          img {
            width: 4vw;
          }
        }


      }

      .search-master-game-options-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;

        .search-master-game-options-card {
          width: 8vw;
          border-radius: 10px;
          margin-bottom: 10px;
          margin-left: 3vw;

          img {
            width: 100%;
            border-radius: 10px;
            @include shadow;
          }

        }
      }

      .search-master-game-options-animation {

        .search-master-game-animation {
          width: 13vw;
          margin-bottom: -3vw;
        }
      }

    }

    .search-master-game-celebration {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .search-master-game-level-animation {
        .search-master-game-level-over {
          width: 15vw;
        }
      }

      .search-master-game-next-level {
        background-color: $yellow-color;
        border-radius: 10px;
        border: 5px solid #fff;
        padding: 5px 15px;
        @include shadow;
        letter-spacing: 1px;
      }
    }

    .search-master-game-music-button {
      position: absolute;
      top: 1vh;
      left: 1vh;
      width: 4vw;

      img {
        width: 100%;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .search-master-game-exit-button {
      position: absolute;
      top: 1vh;
      right: 3vh;
      width: 3vw;
      height: 3vw;


      img {
        width: 30px;
      }
    }

    .search-master-game-exit-button:hover {
      cursor: pointer;
    }

  }


}

.search-master-game-portrait {
  display: none;
}

@media (orientation: portrait) {
  .search-master-game-container {
    display: none;
  }

  .search-master-game-portrait {
    display: block;
    height: 100vh;
    text-align: center;
    letter-spacing: 1px;
    padding-top: 20vh;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: .9rem;

    img {
      margin-top: 20px;
      width: 75px;
    }
  }
}