@import "../../../styles/common.styles.scss";
.question-history-container {
  .header-panel {
    margin: 20px;
    padding: 15px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .header-field {
      margin-top: 20px;
      min-width: 200px;
      Button {
        margin-left: 10px;
      }
    }
  }
  .bottom-panel {
    background-color: #fff;
    .history-request-title {
      @include main-heading;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    .newQuestionForm {
      margin: 20px;
      padding: 15px;
      .header-field {
        margin-top: 5px;
        margin-right: 10px;
        min-width: 200px;
      }
      .inline-field {
        display: inline-block;
        width: 40%;
        margin-right: 10px;
      }
    }
    .history-answers-details {
      @include shadow;
      padding: 15px;
      .history-answers-row {
        animation: move-up 1s ease;
        .history-answers-label {
          margin-bottom: 15px;
          border-bottom: 1px solid #ccc;
          border-top: 1px solid #ccc;
          font-size: 0.8rem;
          letter-spacing: 1px;
          font-weight: bold;
          color:$gray-color;
          .updator{
            margin: 10px;
            color: $red-color;
          }
          .creator{
            margin: 10px;
            color: $blue-color;
          }
        }

        .history-answers-question {
          color: $purple-color;
          letter-spacing: 1px;
          font-weight: bold;
          margin-bottom: 10px;

          img {
            width: 25%;
          }
        }

        .history-answers-option-text {
          margin-top: 5px;
          letter-spacing: 1px;
          color: $blue-color;
          padding: 3px 10px;
          margin: 0px 5px;
          border-radius: 20px;

          img {
            width: 50%;
          }
        }

        .selected-answer {
          background-color: $red-color;
          color: #fff;
        }

        .correct-answer {
          background-color: $green-color;
          color: #fff;
        }
        .questionListIcon {
          color: #004aad;
          vertical-align: middle;
          .icon {
            margin: 5px;
          }
        }
        .questionListIcon:hover {
          cursor: pointer;
        }
      }
    }
  }
}
