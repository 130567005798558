@import "../../../styles/common.styles.scss";

.requestContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  width: 500px;
  .requestFormContainer {
    @include shadow;
    margin: 10px;
    padding: 20px 40px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    .requestForm {
      .formField {
        text-align: left;
      }
      .sendButton {
        text-align: center;
      }
    }
    .requestTitle {
      @include main-heading;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }

    .sendButton {
      text-align: center;
      padding-top: 30px;
      Button {
        background-color: $purple-color;
        letter-spacing: 1px;
      }
    }

    .requestInfo {
      display: flex;
      justify-content: space-around;
      padding-top: 10px;
      border-top: 1px solid #ccc;
      margin-top: 20px;
      color: $blue-color;
      span {
        vertical-align: top;
        color: $purple-color;
      }
    }
  }
}

.error-text {
  color: $red-color;
  font-size: small;
}

// Media queries
@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .requestContainer {
    width: 100%;
    .requestFormContainer {
      margin: 20px 0;
      margin-left: 20px;
    }
    .requestInfo {
      flex-direction: column;
    }
  }
}
