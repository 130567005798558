@import '../../../styles/common.styles.scss';

.orders-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  animation: move-up 1s ease;
  width: 92vw;
  margin-left: 4vw;
  border-radius: 10px;

  .no-orders-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 1px;
    img{
      width: 50vw;
    }
  }

  .order-list {
    .order-list-row {
      border-bottom: 1px solid #ccc;
      padding: 10px 10px;
      font-size: .8rem;
      text-align: left;

      .order-info {
        display: flex;
        justify-content: space-between;
      }

      .order-list-label {
        color: $purple-color;
        margin-top: 10px;
      }
    }
  }

  .orders-container-pagination {
    padding: 20px 0px;
    border-top: 1px solid #ddd;

    .MuiPagination-ul {
      justify-content: center;
    }
  }

}

.supportContainerMobile {
  width: 100vw;
  min-height: 500px;
  text-align: center;
  margin-top: 20px;
  margin-left: 0;
}