.levelListHeader{
  width: 85vw;  
  margin: 0 auto;
  display: flex;
  color: #fdc453;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 40px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.levelList{
  width: 85vw;
  margin: 0 auto;
  margin-top: 30px;
}