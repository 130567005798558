@import "../../../styles/common.styles.scss";

.match-game-container {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;

  .match-game-loader {
    height: 100%;
    width: 100%;
    background-color: yellowgreen;
  }

  .match-game-start-screen {
    height: 100%;
    background-color: yellowgreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Patrick Hand", cursive;

    .match-game-title {
      font-weight: bolder;
      font-size: 3rem;
      color: yellow;
      letter-spacing: 3px;
      text-shadow: 2px 2px #000;
      text-transform: uppercase;
    }

    .match-game-start-image {
      width: 20vw;
    }

    .match-game-start-play-button {
      display: inline-block;
      padding: 1vh 4vh;
      letter-spacing: 4px;
      border-radius: 5vh;
      font-size: 2rem;
      font-weight: bold;
      background-color: green;
      color: yellow;
      box-shadow: 1px 2px 5px #555;
      border: 3px solid yellow;
    }

    .match-game-start-play-button:hover {
      cursor: pointer;
    }

    .match-game-start-exit-button {
      display: inline-block;
      padding: 1vh 4vh;
      letter-spacing: 4px;
      border-radius: 5vh;
      margin-left: 2vw;
      font-size: 2rem;
      font-weight: bold;
      background-color: red;
      color: yellow;
      box-shadow: 1px 2px 5px #555;
      border: 3px solid yellow;
    }

    .match-game-start-exit-button:hover {
      cursor: pointer;
    }

    .match-game-start-music-button {
      width: 5vw;
      margin-top: 2vh;
      background-color: black;
      border-radius: 5vw;

      img {
        width: 100%;
      }

      img:hover {
        cursor: pointer;
      }
    }
  }

  .match-game-play-area {
    height: 100%;
    width: 100%;

    .match-game-bg {
      height: 100%;
      width: 100%;
      position: absolute;
      overflow: hidden;

      img {
        width: 100%;
        height: 100vh;
      }
    }
    .match-game-level-button {
      position: absolute;
      top: 1vh;
      left: 1vh;
      width: 8vw;
      color: #fff;
      background-color: $purple-color;
      border-radius: 5vw;
      padding: 2px 12px;
    }

    .match-game-music-button {
      position: absolute;
      top: 1vh;
      left: 1vh;
      width: 4vw;
      background-color: black;
      border-radius: 5vw;

      img {
        width: 100%;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .match-game-exit-button {
      position: absolute;
      top: 1vh;
      right: 1vh;
      width: 3vw;
      height: 3vw;
      background-color: rgba(255, 0, 0, 0.9);
      color: white;
      border-radius: 3vw;
      border: 2px solid #000;
      box-shadow: 1px 3px 7px #333;
      display: flex;
      font-size: 1.5rem;
      font-weight: bold;
      justify-content: center;
      align-items: center;
    }

    .match-game-exit-button:hover {
      cursor: pointer;
    }

    .match-game-list-container {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      font-family: "Patrick Hand", cursive;
      width: 100vw;
      min-height: 20vh;

      .match-game-list-backdrop {
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid yellow;
        width: 100%;

        .match-game-list-title {
          letter-spacing: 2px;
          font-weight: bold;
          font-size: 1.2rem;
          letter-spacing: 3px;
          color: yellow;
          text-align: center;
          text-shadow: 2px 2px #000000;
          text-transform: uppercase;
          padding: 0 2vw;
        }

        .match-game-list-item {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .match-game-list-item-image {
            background-color: #fff;
            height: 20vh;
            width: 20vh;
            margin: 2vh;
            border-radius: 1vh;
            box-shadow: 5px 5px 10px #333;

            img {
              width: 100%;
              border-radius: 1vh;
            }
          }
        }

        .match-game-level-clear {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .match-game-level-clear-animation {
            width: 30vw;
            text-align: center;
          }

            .match-game-next-level{
              .match-game-next-level-button {
                margin: 2vh;
                display: inline-block;
  
                img {
                  width: 10vw;
                }
  
                img:hover {
                  cursor: pointer;
                }
              }
            }
          
        }
      }
    }

    .match-game-question-answer-container {
      position: relative;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 10px;
      border-radius: 20px;
      width: fit-content;
      top: 40px;
      min-width: 90vh;
      
      .match-game-correct-answer {
        width: 40vh;
        height: 40vh;
      }
      
      .match-game-question {
        top: 5px;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: 3px;
        color: yellow;
        text-align: center;
        text-shadow: 2px 2px #000000;
        text-transform: uppercase;
        padding: 0 2vw;
      }
      
      .match-game-image-container{
        display: flex;
        justify-content: space-evenly;
      .match-game-question-container {
        font-family: "Patrick Hand", cursive;
        text-align: center;
        .match-game-question-backdrop {
          border-radius: 1vw;
          width: 100%;

          .match-game-question-image {
            width: 40vh;
            height: 40vh;
            border-radius: 1vh;
            display: flex;

            img {
              width: 100%;
              border-radius: 1vh;
            }
          }
        }
      }
      .match-game-answer-container {
        overflow: hidden;
        .match-game-answer-backdrop {
          border-radius: 1vw;
          width: 100%;
          text-align: center;
          .match-game-answer-image {
            width: 40vh;
            height: 40vh;
            border-radius: 1vh;
            display: flex;

            img {
              width: 100%;
              border-radius: 1vh;
            }


            .match-game-answer-blank-box {
              height: 40vh;
              width: 40vh;
              display: flex;
              justify-content: center;
              background-color: rgba(255, 255, 255, 0.8);
              border-radius: 1vh;
              border: 3px solid orange;
              align-items: center;
              font-size: 2rem;
              color: grey;
            }
          }

          .match-game-answer-arrow {
            width: 20vh;
            height: 20vh;
            display: flex;

            img {
              width: 100%;
            }
          }
        }
      }
    }
    }
  }

  .match-game-animation {
    position: absolute;
    bottom: 25vh;
    left: 2vh;
    width: 30vh;
    background-color: none;
  }
}

.match-game-portrait {
  display: none;
}

@media (orientation: portrait) {
  .match-game-container {
    display: none;
  }

  .match-game-portrait {
    display: block;
    height: 100vh;
    text-align: center;
    letter-spacing: 1px;
    padding-top: 20vh;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 0.9rem;

    img {
      margin-top: 20px;
      width: 75px;
    }
  }
}
