@import "../../styles/common.styles.scss";

.activity-list-page {
  @include page-container;
  min-height: 100vh;
  padding-top: 60px;
  user-select: none;

  .activity-image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .activity-image {
      width: 15vw;
      height: 35vh;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 9px 10px rgba(86, 124, 173, 0.25);
      padding: 5px;
      .participate-name {
        margin-left: 20px;
        font-size: 16px;
        font-weight: bold;
        color: $purple-color;
      }
      .uploaded-image-box {
        height: 10vw;
        cursor: pointer;
        .uploaded-image {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .control {
        width: 100%;
        .score {
          width: 50%;
        }
        Button {
          display: inline;
        }
      }
    }
  }

  .activity-search {
    background-color: #fff;
    @include shadow;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 25px;
    .formField {
      Button {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
  .sendButton {
    margin-top: 20px;
    margin-left: 10px;
  }
  .uploaded-image {
    margin-left: 20px;
  }
  .control {
    margin-left: 20px;
  }
  .pagination {
    text-align: center;
  }
}
