.my-story-page {
  background-color: #fff;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;


  .my-story-controls {
    width: 100vw;
    height: 10vh;
    position: absolute;
    bottom: 0;
    background-color: #a15d98;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .my-story-exit {
      min-width: 20vw;
      text-align: center;
    }

    .my-story-page-controls {
      display: flex;
      min-width: 40vw;
      justify-content: space-around;
    }

    img {
      height: 5vh;
    }
  }

  .my-story-background {
    width: 100vw;
    height: 90vh;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    z-index: -1;

    img {
      height: 100%;
    }
  }

  .my-story-top-image {
    width: 100vw;
    height: 30vh;
    text-align: center;
    margin-bottom: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 30vh;
    }
  }

  .my-story-bottom-image {
    width: 100vw;
    height: 40vh;
    text-align: center;
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10vh;

    img {
      height: 40vh;
    }
  }

  .my-story-content {
    z-index: 1;
    padding: 20px;
    font-family: 'Pompiere', cursive;
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 1px;
    line-height: 1.5;
    font-weight: bold;
    background-color: rgba(255, 255, 255, .8);
    margin: 20px;
    border-radius: 10px;

  }
}