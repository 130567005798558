@import '../../../../styles/common.styles.scss';

.game-start-screen {
  background-color: $yellow-color;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;

  .game-start-screen-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: -1;

    img {
      width: 100vw;
      height: 100vh;
    }
  }

  .game-start-screen-container {
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    text-align: center;
    z-index: 1;
    color: #fff;
    border-radius: 2vw;
    width: 90vw;
    height: 90vh;
    left: 5vw;
    top: 5vh;

    .game-start-screen-image {
      margin-top: 5vh;

      img {
        width: 10vw;
      }
    }

    .game-start-screen-story {
      padding: 10px;
    }
    .game-start-screen-start-button{
      button {
        font-size: 1.5rem;
        background-color: $yellow-color;
        border-radius: 20px;
        padding: 5px 10px;
        letter-spacing: 1px;
        margin-top: 10px;
      }
    }
.game-start-screen-music-button{
  margin-top: 10px;
  font-size: 1rem;
}
  }
}