@import '../../../../styles/common.styles.scss';

.leaderboard-history-page {
  padding-top: 60px;
  min-height: 100vh;
  @include backgroundStyle;
  position: relative;


  .top-animation {
    position: absolute;
    top: 50px;
    display: flex;
    justify-content: center;
    width: 100%;

    .leaderboard-page-top-animation {
      height: 35vw;
    }
  }

  .leaderboard-history-button {
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;

    span {
      background-color: #fff;
      color: $primary-color;
      padding: 3px 5px;
      border-radius: 10px;
      @include shadow;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .leaderboard-listing {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(20vh);
    justify-content: space-evenly;
    margin-bottom: 60px;


    .leaderboard-card {
      width: 30vw;
      margin-bottom: 20px;
      text-align-last: center;
      background-color: #fff;

      .leaderboard-header {
        padding: 5px;
        letter-spacing: .5px;
        font-size: .8rem;
        background-color: $primary-color;
        color: $yellow-color;
        color: #fff;
      }
      .leaderboard-body {
        letter-spacing: 1px;
        color: $primary-color;
        font-size: 1rem;
        font-weight: bold;
        margin: 10px;
        vertical-align: middle;
      }
      .leaderboard-footer {
        letter-spacing: .5px;
        color: $primary-color;
        font-size: .8rem;
      }
    }
  }

  .gk-listing-pagination{
    display: flex;
    justify-content: center;
  }
}