@import '../../../styles/common.styles.scss';

.redemption-zone-section {
  @include page-container;
  padding: 20px;
  text-align: center;

  .redemption-zone-title {
    font-size: 1.5rem;
    color: $purple-color;
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  .showcase-section {
    min-height: 200px;
    position: relative;
    background-color: #efefff;
    padding: 20px 50px 10px 50px;
    margin-top: 20px;


    .redeem-item {
      padding: 20px 0px;
      font-size: .8rem;
      border-radius: 10px;
      background-color: #fff;
      color: $blue-color;
      font-weight: bold;
      letter-spacing: 1px;
      @include shadow;

      .redeem-item-value {
        color: $purple-color;
        font-size: .8rem;
        letter-spacing: 1px;
      }

      img {
        width: 100%;
      }
    }

    .top-curve {
      img {
        width: 100%;
        position: absolute;
        left: 0;
        top: -5px;
      }
    }

    .bottom-curve {
      img {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }


}




// Media queries
@media only screen and (max-width: 767px) {
  .redemption-zone-section {

    .redemption-zone-title {
      font-size: 1.2rem;
    }

    .showcase-section {
      padding: 0;
    }
  }
}