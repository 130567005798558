@import '../../../styles/common.styles.scss';

.redeem-gift-card {
  position: relative;
  @include shadow;
  background-color: #fff;
  border-radius: 10px;
  animation: move-up 1s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .gift-points {
    text-align: right;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;

    span {
      background-color: $yellow-color;
      color: $purple-color;
      padding: 3px 7px;
      font-weight: bold;
      font-size: .8rem;
      letter-spacing: 1px;
      border-radius: 5px;
    }
  }

  .gift-image {
    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .gift-item-details {

    .gift-name {
      text-align: center;
      font-size: .8rem;
      letter-spacing: 1px;
      color: $gray-color;
      padding: 0px 10px;
    }

    .gift-redeem-button {
      text-align: center;
      padding: 10px;
      min-height: 40px;
      width: 100%;

      button {
        background-color: $purple-color;
        color: #fff;
        letter-spacing: 1px;
      }

    }

    .gift-need-more-points {
      text-align: center;
      padding: 5px;
      letter-spacing: .5px;
      font-size: .8rem;
      background-color: $gray-color;
      color: #fff;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.redeem-gift-card:hover {
  cursor: pointer;
  margin-top: -10px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.4);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}