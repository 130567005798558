  @import '../../../../styles/common.styles.scss';

  .chicken-eggs-game-box {
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 0;
    user-select: none;


    .game-level-number {
      position: absolute;
      left: 5px;
      top: 5px;
      background-color: $yellow-color;
      padding: 2px 5px;
      border-radius: 5px;
    }

    .chicken-eggs-game-background {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
      }

    }

    .game-word-list {
      margin-top: 10px;
      display: flex;
      justify-content: center;

      .game-word {
        width: 3vw;

        .wrong-alphabet {
          animation: dance 1s ease;
        }

        img {
          width: 100%;
        }
      }

    }

    .game-word-blanks {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .game-word-blank-letter {
        border-bottom: 4px solid #000;
        width: 3vw;
        margin: 10px;
        font-size: 2rem;
        font-weight: bold;
        color: $purple-color;
      }
    }

    .question-alphabets {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      .game-timer-section {
        margin-bottom: 5px;
        width: 5vw;
        border-radius: 5vw;
        display: inline-block;
      }


      .game-question-section {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: #000;
        width: 100vw;
        background-color: rgba(255, 255, 0, .8);
        padding: 5px 0px;

        .game-question {
          padding: 5px 20px;
          letter-spacing: 4px;
          border-radius: 10px;
        }
      }

      .game-alphabets-options {
        background-color: rgba(0, 0, 0, .7);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .option-alphabet {
          width: 3vw;
          height: 3vw;
          line-height: 3vw;
          font-size: 1.2rem;
          vertical-align: middle;
          background-color: rgba(255, 255, 255, .9);
          margin: 10px 10px;
          border-radius: 5px;
          border: 3px solid $yellow-color;
        }

        .option-alphabet:hover {
          cursor: pointer;
          background-color: $yellow-color;
          border-color: #000;
        }

        .alphabet-selected {
          width: 3vw;
          height: 3vw;
          line-height: 3vw;
          font-size: 1.2rem;
          vertical-align: middle;
          margin: 10px 10px;
          border-radius: 5px;
          border: 3px solid $yellow-color;
          background-color: #555;
        }
      }
    }

    .bonus-message {
      background-color: rgba(255, 155, 0, .9);
      width: 60%;
      margin-left: 20%;
      color: black;
      padding: 5px;
      letter-spacing: 2px;
      font-size: 1.2rem;
      border-radius: 10px;
      font-weight: bold;
    }

    .answer-out {
      text-align: center;
      overflow: hidden;

      img {
        height: 13vw;
        margin-top: 10px;
        animation: zoom-in-zoom-out 2s ease forwards;
      }

      .game-button {
        background-color: #000;
        color: $yellow-color;
        padding: 5px 10px;
        border-radius: 10px;
        display: inline-block;
        margin-top: 10px;
        margin-left: 10px;
        letter-spacing: 1px;
        border: 3px solid #000;
      }

      .game-button:hover {
        cursor: pointer;
        background-color: $purple-color;
      }
      
    }

    .start-screen {
      width: 100%;
      height: 80%;
      margin-top: 5%;
      background-color: rgba(0, 0, 0, .8);
      text-align: center;
      letter-spacing: 1px;
      font-size: 2rem;
      color: #fff;

      .start-screen-message {
        font-family: 'Patrick Hand', cursive;
        padding: 10px;
      }

      img {
        width: 10vw;
        margin-top: 5vw;
      }

      button {
        font-size: 1.5rem;
        background-color: $yellow-color;
        border-radius: 20px;
        padding: 5px 10px;
        letter-spacing: 1px;
        margin-top: 30px;
      }

      button:hover {
        cursor: pointer;
        background-color: $red-color;
      }
    }

    .music-switch {
      margin-top: 20px;
      font-size: 1rem;
    }

  }

  @keyframes zoom-back {
    0% {
      transform: scale(1, 1);
    }

    50% {
      margin-top: 100px;
      transform: scale(3, 3);
    }

    100% {
      transform: scale(1, 1);
    }
  }



  // Media queries
  @media only screen and (max-width: 1023px) {

    /* For mobile phones: */
    .chicken-eggs-game-box {
      .game-level-number {
        font-size: .8rem;
      }

      .start-screen {
        font-size: 1rem;

        button {
          font-size: .8rem;
        }
      }

      .water-bubble-container {
        .water-bubble {
          .bubble-value {
            font-size: .8rem;
          }
        }
      }
    }
  }