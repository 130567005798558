@import "../../../styles/common.styles.scss";
.active-users-content-report{
  margin: 20px;
  
  .userControlBox {
    margin-bottom: 10px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    .wp-box{
        width: 500px;
      }
    .user-control {
        min-width: 150px;
        margin: 10px;
        vertical-align: bottom;
        display: inline-block;
        .userLabel {
            font-size: 1.2rem;
            color: $purple-color;
            font-weight: bold;
            letter-spacing: 1px;
        }

        .userValue {
            font-size: 1.2rem;
            color: #333;
            font-weight: bold;
            letter-spacing: 1px;
        }
        .formButton {
            vertical-align: bottom;
        }
    }
}
}