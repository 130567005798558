@import '../../../../styles/common.styles.scss';


.contest-certificate-container {
  background-color: #ccc;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

  .contest-certificate {
    background-color: #fff;
    width: 800px;
    height: 1200px;
    overflow: hidden;
    position: relative;
    -webkit-print-color-adjust: exact;

    .certificate-top-image {
      .shape-1 {
        background-color: $yellow-color;
        box-shadow: 0px 2px 10px #555;
        transform: rotate(-3deg);
        width: 1500px;
        margin-left: -50px;
        min-height: 120px;
        margin-top: -40px;
        z-index: 2;
      }

      .shape-2 {
        background-color: $blue-color;
        box-shadow: 0px 2px 10px #555;
        z-index: -1;
        transform: rotate(5deg);
        width: 1500px;
        margin-left: -50px;
        min-height: 120px;
        margin-top: -90px;
      }
    }

    .certificate-header {
      margin-top: 100px;
      display: flex;
      justify-content: center;

      .certificate-header-ribbon {
        img {
          width: 150px;
        }
      }

      .certificate-header-text {
        font-size: 2rem;
        text-align: center;
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 1;
        align-self: center;

        div {
          margin-top: 10px;
        }
      }
    }

    .certificate-content {
      text-align: center;
      width: 80%;
      margin-left: 20%;
      margin-top: 100px;
      font-size: 1.2rem;

      .student-name {
        font-size: 2rem;
        color: $purple-color;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
        text-transform: capitalize;
      }

      span {
        color: $purple-color;
        font-weight: bold;
      }

      .school-logo {
        img {
          width: 150px;
        }
      }
    }

    .certificate-date-signature {
      padding: 0px 50px;
      margin-top: 120px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .date-signature-container {
        font-size: .9rem;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;

        .value {
          border-bottom: 1px solid #ccc;

          img {
            width: 100px;
          }
        }

        .label {
          color:$gray-color;
        }
      }
    }

    .certificate-footer {

      .footer-logo {
        position: absolute;
        bottom: 0;
        left: 20px;

        img {
          width: 200px;
        }
      }

      .footer-shape {

        position: absolute;
        bottom: -50px;

        .shape-1 {
          background-color: $yellow-color;
          box-shadow: 0px 2px 10px #555;
          transform: rotate(-20deg);
          width: 1500px;
          margin-left: -50px;
          min-height: 150px;
          margin-top: 120px;
          z-index: 2;
        }
      }
    }
  }
}
.certificate-buttons {
  text-align: right;
  margin-right: 20px;
  letter-spacing: 1px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  span {
    background-color: $blue-color;
    color: #fff;
    padding: 5px 10px;
    margin: 2%;
    border-radius: 20px;
  }

  .social-share {
    margin: 2%;
  }

  span:hover {
    cursor: pointer;
  }
}

// Media queries
@media only screen and (max-width: 767px) {

  .contest-certificate-container {

    .contest-certificate {
      width: 300px;
      height: 450px;

      .certificate-top-image {
        .shape-1 {
          margin-left: -50px;
          min-height: 60px;
          margin-top: -60px;
        }

        .shape-2 {
          margin-left: -50px;
          min-height: 120px;
          margin-top: -50px;
        }
      }

      .certificate-header {
        margin-top: 5px;
        display: flex;
        justify-content: center;

        .certificate-header-ribbon {
          img {
            width: 50px;
          }
        }

        .certificate-header-text {
          font-size: 1rem;

          div {
            margin-top: 10px;
          }
        }
      }

      .certificate-content {
        width: 90%;
        margin-left: 5%;
        margin-top: 20px;
        font-size: .6rem;

        .student-name {
          font-size: 1rem;
          margin-top: 3px;
          margin-bottom: 3px;
          text-transform: capitalize;
        }

        .school-logo {
          img {
            width: 70px;
          }
        }
      }

      .certificate-date-signature {
        padding: 0px 10px;
        margin-top: 20px;

        .date-signature-container {
          font-size: .5rem;

          .value {
            border-bottom: 1px solid #ccc;

            img {
              width: 40px;
            }
          }
        }
      }

      .certificate-footer {
        .footer-logo {
          position: absolute;
          bottom: 0;
          left: 20px;

          img {
            width: 70px;
          }
        }

        .footer-shape {
          position: absolute;
          bottom: 50px;

          .shape-1 {
            background-color: $yellow-color;
            box-shadow: 0px 2px 10px #555;
            transform: rotate(-20deg);
            width: 1500px;
            margin-left: -50px;
            min-height: 150px;
            margin-top: 120px;
            z-index: 2;
          }
        }
      }
    }
  }
}



// Media queries
@media only screen and (min-width: 768px) {

  .contest-certificate-container {

    .contest-certificate {
      width: 600px;
      height: 900px;

      .certificate-top-image {
        .shape-1 {
          margin-left: -50px;
          min-height: 100px;
          margin-top: -40px;
        }

        .shape-2 {
          margin-left: -50px;
          min-height: 120px;
          margin-top: -70px;
        }
      }

      .certificate-header {
        margin-top: 70px;
        display: flex;
        justify-content: center;

        .certificate-header-ribbon {
          img {
            width: 100px;
          }
        }

        .certificate-header-text {
          font-size: 2rem;

          div {
            margin-top: 10px;
          }
        }
      }

      .certificate-content {
        width: 80%;
        margin-left: 10%;
        margin-top: 50px;
        font-size: .9rem;

        .student-name {
          font-size: 2rem;
          text-transform: capitalize;
        }

        .school-logo {
          img {
            width: 70px;
          }
        }
      }

      .certificate-date-signature {
        padding: 0px 20px;
        margin-top: 70px;

        .date-signature-container {
          font-size: .8rem;

          .value {
            border-bottom: 1px solid #ccc;

            img {
              width: 75px;
            }
          }
        }
      }

      .certificate-footer {
        .footer-logo {
          position: absolute;
          bottom: 0;
          left: 20px;

          img {
            width: 120px;
          }
        }

        .footer-shape {
          position: absolute;
          bottom: 10px;

          .shape-1 {
            background-color: $yellow-color;
            box-shadow: 0px 2px 10px #555;
            transform: rotate(-20deg);
            width: 1500px;
            margin-left: -50px;
            min-height: 150px;
            margin-top: 150px;
            z-index: 2;
          }
        }
      }
    }
  }
}
  @media print {
    html, body {
      max-height: 100%;
      page-break-after: always;
    }
    body * {
        visibility: hidden;
    }

    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute; 
        width: 100%;
        left: 0;
        top: 0;
    }

    #no-print {
        visibility: hidden;
    }

}