@import '../../../styles/common.styles.scss';

.video-listing {

  .video-card {
    background-color: #fff;
    min-height: 180px;
    border-radius: 10px;
    margin: 15px 10px 20px 10px;
    @include shadow;

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .video-title {
      color: $purple-color;
      letter-spacing: 1px;
      padding: 0px 1px;
    }
  }

  .video-card:hover {
    cursor: pointer;
    animation: box-move-up .5s ease forwards;
  }
}

// Media queries
@media only screen and (max-width: 1023px) {
  .video-listing {
    .video-card {

      .video-title {
        font-size: .9rem;
      }
    }
  }
}